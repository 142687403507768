import { MediaServerTypes } from '@/constants'
import moment from 'moment'

export const objectContainsKey = (object, key) => {
    return typeof object === 'object' && object && object[key] !== undefined
}

export const getMediaPath = (media = null) => {
    if (media && media.BasePath && media.Path) {
        if (media?.Type && media.Type == MediaServerTypes.Video) {
            return `${media.BasePath}${media.ThumbPath}`
        } else {
            return `${media.BasePath}${media.Path || media.ThumbPath}`
        }
    }
    return ''
}

export const getStatusOptions = (statusCode) => {
    return Object.keys(statusCode).map((item) => {
        return {
            label: statusCode.getDisplayTextKey(statusCode[item]),
            value: statusCode[item],
        }
    })
}

export const validateValue = (value = null) => {
    if (value) {
        return value
    } else {
        return '-'
    }
}

export const ifDocument = (fileUrl) => {
    let fileExtension = fileUrl.split('.').pop()
    switch (fileExtension) {
        case 'pdf':
            return true
        case 'docx':
            return true
        case 'doc':
            return true
        default:
            return false
    }
}

export const getMonthDates = (month) => {
    return {
        StartDate: moment(month, 'MMMM').startOf('month').format('YYYY-MM'),
        EndDate: moment(month, 'MMMM')
            .add('month', 1)
            .startOf('month')
            .format('YYYY-MM'),
    }
}

export function showModal(id, context, cb = () => {}) {
    if (context) {
        context.root.$bvModal.show(id)
    } else {
        this.$bvModal.show(id)
    }
    cb()
}

export function hideModal(id, context, cb = () => {}) {
    if (context) {
        context.root.$bvModal.hide(id)
    } else {
        this.$bvModal.hide(id)
    }
    cb()
}

export const emptyFields = (keys = []) => {
    if (keys) {
        keys.forEach((item) => (item.value = null))
    }
}

export const truncateString = (content, limit = 30) => {
    return content.slice(0, limit)
}

export const getFileSizeInMB = (size) => {
    return size / 1024 / 1024
}

export const getRandomBetweenTwoNum = (min, max) => {
    if (!min || !max) return
    let minNum = Math.ceil(min)
    let maxNum = Math.floor(max)
    return Math.floor(Math.random() * (maxNum - minNum) + minNum)
}

export const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
}

export const swapObjectKeyValue = (obj = {}) => {
    if (!obj) return

    let objData = { ...obj }
    let swapedObj = {}

    for (const key in objData) {
        swapedObj[objData[key]] = key
    }

    return swapedObj
}
