<template>
	<b-row>
		<b-col lg="12" md="12" sm="12">
			<b-card>
				<b-row class="mb-3">
					<b-col lg="5">
						<b-row lg="4">
							

							<b-col lg="7" class="align-self-center pt-2">
							<Title text="General Information" />
								<LabelText
									label="Full Name"
									:text="currentUser.firstName"
									labelClasses="wd-90 "
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
								<LabelText
									label="Last Name"
									:text="currentUser.lastName"
									labelClasses="wd-90 "
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
								<LabelText
							label="Email Address"
							:text="currentUser.email"
							labelClasses="wd-200"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
								
								<LabelText
									label="Company Address"
									:text="currentUser.address"
									labelClasses="wd-200"
									textClasses="font-bold wd-200"
									containerClasses="mb-8"
								/>

							</b-col>
						</b-row>
					</b-col>
					<b-col lg="4" sm="auto" class="align-self-center pt-2">
					<Title text="Company Information" />
			
						<LabelText
							label="Company"
							:text="currentUser.company"
							labelClasses="wd-80"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>

						<LabelText
							label="SubDomain"
							:text="currentUser.subdomain+'.ubnotify.app'"
							labelClasses="wd-80"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>

						<LabelText
							label="Number of Events"
							:text="currentUser.numberOfEvents||0"
							labelClasses="wd-200"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>

						<LabelText
							label="Messages Remaining"
							:text="currentUser.messagesSend || 0+' out of '+currentUser.plan.totalMessages"
							labelClasses="wd-200"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>

						<LabelText
							label="Additional Charges (1 cent/message)"
							:text="currentUser.totalUsage"
							labelClasses="wd-200"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
					</b-col>

				<b-col lg="3" sm="auto" class="btn-wrapper">
						<Button
							size="md"
							buttonVariant="primary"
							classes="wd-211"
							buttonText="Delete Client"
							:clickHandler="
									() =>
										$bvModal.show(
											`action-${currentUser.id}-modal`
										)"
						/>
					</b-col>

				</b-row>	
				

<hr/>
<b-row class="mb-3">
					<b-col lg="5">
						<b-row lg="4">
							

							<b-col lg="7" class="align-self-center pt-2">
						<div class="w-100 plan-box">
                        <b-avatar class="earning-icon">
									<img
										src="@/assets/images/icons/basic.png"
									/>
								</b-avatar>
							<div
								class="
									d-flex
									justify-content-between
									align-content-start
								"
							>
                            <div class="price-info">
                            <div
										class="sub-text-title"
										:class="statisticTitleClasses"
										>Basic</div>
								</div>
                                </div>
							</div>
						</div>
							</b-col>
						</b-row>
					</b-col>
					<b-col lg="4" sm="auto" class="align-self-center pt-2">
			
						<LabelText
							label="Twilio Number"
							:text="currentUser.twilioPhoneNumber"
							labelClasses="wd-200"
							textClasses="font-bold"
							containerClasses="mb-8"
						/>
						<LabelText
									label="Plan Expires:"
									:text="convertDateTime({
								date: currentUser.createdAt,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})"
									labelClasses="wd-200"
									textClasses="font-bold"
									containerClasses="mb-8"
								/>
					</b-col>

					
				</b-row>

				
			</b-card>
		</b-col>
		<ConfirmModal
			:isLoading="isLoading"
			:isSuccess="isUserStatusChangedSuccess"
			:onOkayPress="handleOkayPress"
			successText="Success !"
			:onYesPress="updateUserStatus"
			:id="`action-${currentUser.id}-modal`"
			:messageText="
				'Are you sure you want to delete this client?'
			"
			:messageIcon="require('@/assets/images/icons/restrict-icon.svg')"
		/>
	</b-row>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BBadge,
	BOverlay,
} from 'bootstrap-vue'
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	ref,
} from '@vue/composition-api'

import ConfirmModal from '../../../components/ConfirmModal.vue'
import Avatar from '../../../components/Avatar.vue'
import LabelText from '../../../components/LabelText.vue'
import Button from '../../../components/Button.vue'
import Title from '../../../components/Title.vue'
import CatalogueTable from './CatalogueTable.vue'
import { getUserDetail } from '../../../api/api.service'
import { useStore } from '../../../store'
import { getMediaPath } from '../../../utils/commonUtils'
import { APP_ROUTES, getRoute } from '../../../helpers/routeHelpers'
import { UserStatusCode } from '../../../constants'
import { dateTimeFormat, convertDateTime } from '../../../utils/dateFormatUtils'
import { numberFormatter } from '../../../utils/formatNumber'

// const avatar = require("../../.FF./assets/images/avatars/12.png");
const crownIcon = require('../../../assets/images/icons/crown-icon.svg')

export default {
	name: 'UserDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BBadge,
		LabelText,
		Avatar,
		Button,
		Title,
		CatalogueTable,
		BOverlay,
		ConfirmModal,
	},
	setup: (props, context) => {
		const userId = Number(context.root.$route.params.userId)
		const store = useStore()
		const state = reactive({ currentUser: {}, updateUserLoading: false })

		const isLoading = ref(false)
		const isUserStatusChangedSuccess = ref(false)

		state.currentUser =
			computed(() => store.getters['user/getUserById'](userId)).value ||
			{}

		onMounted(() => {
			getUserDetail(userId).then((res) => {
				state.currentUser = res
			})
		})

		function showReviews() {
			context.root.$router.push(
				getRoute(APP_ROUTES.USER_REVIEWS, { userId })
			)
		}

		function updateUserStatus() {
			isLoading.value = true
			state.updateUserLoading = true
			store
				.dispatch('user/updateUserStatus', {
					id: state.currentUser.id,
				})
				.then((res) => {
					console.log("res",res)
					isUserStatusChangedSuccess.value = true
				})
				.finally(() => {
					state.updateUserLoading = false
					isLoading.value = false
				})
		}

		function handleOkayPress() {
							context.root.$router.push(
				getRoute(APP_ROUTES.USER_MANAGEMENT)
			)
		}

		return {
			...toRefs(state),
			// avatar,
			showReviews,
			crownIcon,
			getMediaPath,
			userStatusCode: UserStatusCode,
			updateUserStatus,
			handleOkayPress,
			isUserStatusChangedSuccess,
			isLoading,
			convertDateTime,
			dateTimeFormat,
			numberFormatter
		}
	},
}
</script>

<style scoped>
.text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.sub-text-title{
	font-size:20px;
	font-weight: bold
}

.label {
	size: 14px;
	color: #888888;
}

::v-deep .font-bold {
	font-weight: 500 !important;
}

.fw-500 {
	font-weight: 500;
	color: #000;
}

.crown {
	width: 14px;
	height: 14px;
	align-self: center;
}
.align-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}
.icon-wrapper {
	display: flex;
	align-items: center;
}
.icon-wrapper p {
	margin-bottom: 0px;
	padding-left: 10px;
}
.align-wrapper p {
	margin-bottom: 0px;
}
.align-wrapper p.label {
	width: 130px;
}

.ml-50 {
	margin-left: 50px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-29 {
	margin-bottom: 26px;
}

.mt-30 {
	margin-top: 30px;
}

.avatar-padding {
	padding-left: 25px;
}

.btn-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

@media only screen and (max-width: 600px) {
	.btn-wrapper {
		align-items: flex-start;
	}
}

::v-deep .wd-120 {
	width: 120px;
}
.subscription-wd-120 {
	width: 120px !important;
}
.wd-120 {
	width: 120px;
}

::v-deep .w-130 {
	width: 130px;
}

::v-deep .wd-211 {
	width: 211px;
}

::v-deep .wd-90 {
	width: 90px;
}

.w-130 {
	width: 130px;
}
.plan-box{
    border: 1px solid #F78F21;
    border-radius : 6px;
    padding : 10px;
    display : flex;
    flex-direction: row;
    align-items: center;
	justify-content: center;
}
.plan-dot{
    height: 9px;
  width: 9px;
  background-color: #F78F21;
  border-radius: 50%;
  display: inline-block;
  margin-right:5px;
  margin-top:5px;
}
.guide-trip-earning-icon {
	color: #226694;
	background-color: #3790d51f;
}

.card-container {
	height: 200px !important;
}
.spinner {
	width: 4rem;
	height: 4rem;
}
.plan-details{
    display:flex;
    flex-direction:column;
}
.plan-detail-sub{
    display:flex;
    flex-direction:row;
     justify-content: space-between
}
.plan-text{
}
.price-info{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
	margin-left:20px
}
.price{
    display:flex;
    flex-direction:row;
    justify-content: space-between

}
.price-label{
    color:#F78F21;
    font-size:30px;
}
</style>
