<template>
	<div>
		<b-row>
			<b-col cols="12" sm="12" md="6" lg="6" xl="6">
				<div class="d-flex align-items-center">
					<div>
						<p class="mr-1 mt-1">Select Month</p>
					</div>
					<!-- 	:max-date="new Date()" -->
					<MonthPickerInput
						@input="showDate"
						:default-month="new Date().getMonth() + 1"
					/>
				</div>
			</b-col>
			<b-col
				cols="12"
				sm="12"
				md="6"
				lg="6"
				xl="6"
				class="
					d-flex
					align-items-center
					justify-content-lg-end
					justify-content-md-end
					justify-content-sm-center
				"
			>
				<PhotoSelectedText
					text="Total Stories"
					:count="$store.state.monthlySubmission.count"
				/>
			</b-col>
		</b-row>

		<div
			class="d-flex mt-3"
			v-if="
				isError || (!isFetching && !userStories.arrOfUserStories.length)
			"
		>
			<Title text="No Stories Found" />
		</div>
		<div
			class="
				d-flex
				justify-content-center
				align-items-center
				spinner-container
			"
			v-if="isFetching"
		>
			<BSpinner variant="primary" class="spinner" />
		</div>
		<div
			class="
				d-flex
				justify-content-md-center
				justify-content-lg-start
				justify-content-sm-center
				align-items-center
				flex-wrap
			"
			v-if="!isFetching"
		>
			<SubmissionView
				showClickableImage
				v-for="item in userStories.arrOfUserStories"
				:thumbnailImage="getMediaPath(item.Media)"
				:userAvatar="getMediaPath(item.User.Media)"
				:handleDelete="handleDeleteClick"
				:submissionId="item.Id"
				:userName="item.User.FullName"
				:subText="`${
					item.User.CountryCodeAlpha
						? item.User.CountryCodeAlpha + '-'
						: ''
				} ${convertDateTime({
					date: item.CreatedAt,
					customFormat: dateTimeFormat.appDateFormat,
					dateOnly: true,
				})}`"
				:key="item.id"
			>
				<template #clickableImage>
					<div
						@click="previewImage(item)"
						v-b-modal="'preview-image'"
					>
						<StorySlider :mediaObj="item" :isPreview="true" />
					</div>
				</template>

				<!-- <template #showLikes>
					<b-list-group-item class="mt-1 d-flex">
						<feather-icon
							icon="ThumbsUpIcon"
							size="15"
							color="#F78F21"
						/>
						<p style="margin-left: 5px" class="font-weight-bolder">
							{{ item.UserLikeCount }}
						</p>
					</b-list-group-item>
				</template> -->

				<template #showViewersAvatar>
					<GroupedAvatar
						id="modal"
						:likedByProfiles="item.LikeUsers || null"
						:onClick="() => setSelectedSubmissionId(item.Id)"
					/>

					<b-list-group-item class="mt-1 d-flex">
						<feather-icon
							v-if="item.Content"
							@click="handleViewCaption(item)"
							v-b-modal="'caption-modal'"
							class="mr-1"
							icon="EyeIcon"
							size="15"
							color="#F78F21"
							style="margin-top: 12px"
						/>
						<feather-icon
							id="caption"
							icon="ThumbsUpIcon"
							size="15"
							color="#F78F21"
							style="margin-top: 12px"
						/>
						<p
							style="margin-left: 5px; margin-top: 10px"
							class="font-weight-bolder"
						>
							{{ item.UserLikeCount }}
						</p>
						<b-tooltip
							title="View Caption"
							class="cursor-pointer"
							target="caption"
						/>
					</b-list-group-item>
				</template>
			</SubmissionView>

			<BasicModal id="modal" modalTitle="Liked By">
				<template #showAllViewsUsers>
					<AllViewsUsers :submissionId="selectedSubmissionId" />
				</template>
			</BasicModal>

			<BasicModal
				id="preview-image"
				:modalTitle="userName"
				modalBodyClasses="modal-body-height"
			>
				<StorySlider :mediaObj="selectedStory.data" />
			</BasicModal>

			<BasicModal id="caption-modal" modalTitle="Caption">
				<p>
					{{ storyCaption }}
				</p>
			</BasicModal>
		</div>
		<ConfirmModal
			id="user-story-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="User Story Deleted Successfully !"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this story?"
		/>
	</div>
</template>

<script>
import moment from 'moment'
import { BContainer, BRow, BCol, BSpinner, BImg, BTooltip } from 'bootstrap-vue'
import { reactive, ref, onMounted, watch } from '@vue/composition-api'
import { MonthPickerInput, MonthPicker } from 'vue-month-picker'

import SubmissionView from '@/components/SubmissionView.vue'
import GroupedAvatar from '@/components/GroupedAvatar.vue'
import Button from '@/components/Button.vue'
import Select from '@/components/InputSelect.vue'
import InputSelect from '@/components/InputSelect.vue'
import Title from '@/components/Title.vue'
import StorySlider from '@/components/MediaSlider/StorySlider.vue'
import showToast from '@/hooks/useToast'
import BasicModal from '@/components/BasicModal.vue'
import AllViewsUsers from '@/components/AllViewsUsers.vue'
import VideoPlayer from '@/components/MediaSlider//VideoPlayer.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import PhotoSelectedText from '@/components/PhotoSelectedText.vue'
import { useStore } from '@/store'
import { getMonthlySubmissions, deleteUserStory } from '@/api/api.service'
import { getMediaPath, getStatusOptions } from '@/utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'
import { submissionsStatus } from '@/constants'

export default {
	name: 'UserStories',
	components: {
		BContainer,
		BTooltip,
		BRow,
		BCol,
		BSpinner,
		BImg,
		SubmissionView,
		GroupedAvatar,
		Button,
		Select,
		InputSelect,
		Title,
		BasicModal,
		AllViewsUsers,
		MonthPicker,
		MonthPickerInput,
		StorySlider,
		VideoPlayer,
		ConfirmModal,
		PhotoSelectedText,
	},
	setup(props, context) {
		onMounted(() => {
			getPreviousSubmissionsHandler()
			getStoriesCount()
		})

		const store = useStore()
		let isFetching = ref(false)
		let isSuccess = ref(false)
		let isError = ref(false)
		let selectedId = ref(null)
		let isProcessing = ref(false)
		let deleteSuccess = ref(false)
		let storyCaption = ref('')
		const isStatusFilterDisable = ref(false)
		const imageSource = ref('')
		const userName = ref('')
		const submissionStatusValue = ref(null)
		const selectedSubmissionId = ref(null)
		const selectedDate = ref(
			// moment().subtract(1, 'months').format('YYYY-MM')
			moment().format('YYYY-MM')
		)
		const userStories = reactive({ arrOfUserStories: [] })
		const selectedStory = reactive({ data: {} })

		const getPreviousSubmissionsHandler = async () => {
			try {
				isFetching.value = true
				const { UserStories = [] } = await getMonthlySubmissions({
					...(submissionStatusValue.value && {
						Status: submissionStatusValue.value.value,
					}),
					// Date: moment('December', 'MMMM').format('YYYY-MM'),
					Date: selectedDate.value,
					Limit: -1,
				})
				userStories.arrOfUserStories = UserStories
				isFetching.value = false
			} catch (err) {
				isFetching.value = false
				showToast(context.root, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				isFetching.value = false
			}
		}

		const setSelectedSubmissionId = (id) => {
			selectedSubmissionId.value = id
		}

		function showDate(val) {
			if (!val) {
				return
			}

			selectedDate.value =
				val.monthIndex < 9
					? `${val.year}-0${val.monthIndex}`
					: `${val.year}-${val.monthIndex}`

			getPreviousSubmissionsHandler()
			store.dispatch(
				'monthlySubmission/getCountsAction',
				selectedDate.value
			)
		}
		const previewImage = (item) => {
			userName.value = item?.User?.FullName
			selectedStory.data = item
		}

		const submissionStatusHandler = (item) => {
			submissionStatusValue.value = item

			getPreviousSubmissionsHandler()
		}

		const handleYesPress = () => {
			isProcessing.value = true

			deleteUserStory(selectedId.value)
				.then(() => {
					userStories.arrOfUserStories =
						userStories.arrOfUserStories.filter(
							(item) => item.Id !== selectedId.value
						)
					deleteSuccess.value = true
				})
				.finally(() => {
					isProcessing.value = false
				})
		}

		const handleOkayPress = () => {
			deleteSuccess.value = false
			selectedId.value = null
		}

		const handleDeleteClick = (id) => {
			selectedId.value = id
		}

		const getStoriesCount = async () => {
			try {
				await store.dispatch('monthlySubmission/getCountsAction')
			} catch (err) {
				console.log(err)
			}
		}

		const handleViewCaption = (item) => {
			storyCaption.value = item.Content
		}

		return {
			setSelectedSubmissionId,
			convertDateTime,
			getMediaPath,
			showDate,
			previewImage,
			getStatusOptions,
			submissionStatusHandler,
			isStatusFilterDisable,
			submissionStatusValue,
			submissionsStatus,
			imageSource,
			userName,
			isFetching,
			isSuccess,
			isError,
			dateTimeFormat,
			userStories,
			selectedSubmissionId,
			MonthPicker,
			MonthPickerInput,
			selectedStory,
			handleDeleteClick,
			handleYesPress,
			handleOkayPress,
			isProcessing,
			deleteSuccess,
			storyCaption,
			handleViewCaption,
		}
	},
}
</script>

<style scoped>
::v-deep .filter-select {
	min-width: 190px;
}
.spinner-container {
	height: 50vh;
	width: 100%;
}
.spinner {
	width: 4rem;
	height: 4rem;
}

::v-deep .month-picker--default {
	z-index: 9999999999 !important;
}
.month-picker__month .selected {
	background-color: brown !important;
}
</style>
