var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"create-community-card"},[(_vm.isLoading)?[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]:_vm._e(),(!_vm.isLoading)?_c('validation-observer',{ref:"validationRules"},[_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('label',{staticClass:"label-text font-weight-bolder",attrs:{"for":"title"}},[_vm._v("Title")]),_c('validation-provider',{attrs:{"rules":"required|min:3|max:100","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-field',{attrs:{"id":"title","errors":errors},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,239745233)})],1),_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('label',{staticClass:"label-text font-weight-bolder",attrs:{"for":"hashTag"}},[_vm._v("Hashtags")]),_c('validation-provider',{attrs:{"rules":"required","name":"HashTag"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{staticClass:"mb-2",attrs:{"input-id":"tags-basic","separator":" ,;"},model:{value:(_vm.hashtags),callback:function ($$v) {_vm.hashtags=$$v},expression:"hashtags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3612243993)})],1)],1),_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('label',{staticClass:"label-text font-weight-bolder",attrs:{"for":"category"}},[_vm._v("Category")]),_c('validation-provider',{attrs:{"rules":"required","name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-select',{attrs:{"errors":errors,"options":_vm.getStatusOptions(_vm.communityPostCategory),"clearable":false,"value":_vm.communityPostCategory.getDisplayTextKey(
								_vm.categoryId
							),"onChange":_vm.onCategoryChange,"placeholder":"Select Category from List"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,927203486)})],1)],1),_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('div',{staticClass:"file-input"},[_c('div',{staticClass:"file-input-title"},[_vm._v("Featured Image")]),_c('div',{staticClass:"d-flex justify-content-start"},[_vm._l((_vm.imageSelectedPreviews),function(item,index){return _c('span',{key:'previous' + index},[_c('PreviewImage',{attrs:{"imageToPreview":_vm.getMediaPath(item),"crossHandler":function () { return _vm.removeImages(index); }}})],1)}),_vm._l((_vm.selectedFiles),function(item,index){return _c('span',{key:'selected' + index},[_c('PreviewImage',{attrs:{"imageToPreview":item,"localFile":true,"crossHandler":function () { return _vm.emptyPreviewImages(index); }}})],1)}),(
								_vm.imageSelectedPreviews.length +
									_vm.selectedFiles.length <
								4
							)?_c('div',{staticClass:"file-input-uploader"},[_c('div',{staticClass:"uploader"},[_c('FileInput',{attrs:{"isRequired":true,"data":_vm.selectedFiles,"onChange":_vm.handleFileSelection,"multiple":true,"placeholderText":"Add Images"}})],1)]):_vm._e()],2)])])],1),_c('b-row',{staticClass:"form-row editor"},[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('label',{staticClass:"label-text font-weight-bolder",attrs:{"for":"quill"}},[_vm._v("Content")]),_c('validation-provider',{attrs:{"rules":"required|min:20","name":"Content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var errors = ref.errors;
return [_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('quill-editor',{attrs:{"id":"quill","options":_vm.editorOption},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}},[_c('div',{attrs:{"slot":"toolbar","id":"toolbar"},slot:"toolbar"},[_c('button',{staticClass:"ql-bold"},[_vm._v("Bold")]),_c('button',{staticClass:"ql-italic"},[_vm._v("Italic")]),_c('button',{staticClass:"ql-link"},[_vm._v("Link")]),_c('select',{staticClass:"ql-size"},[_c('option',{attrs:{"value":"small"}}),_c('option',{attrs:{"selected":""}}),_c('option',{attrs:{"value":"large"}}),_c('option',{attrs:{"value":"huge"}})]),_c('select',{staticClass:"ql-font"},[_c('option',{attrs:{"selected":"selected"}}),_c('option',{attrs:{"value":"serif"}}),_c('option',{attrs:{"value":"monospace"}})]),_c('button',{staticClass:"ql-script",attrs:{"value":"sub"}}),_c('button',{staticClass:"ql-script",attrs:{"value":"super"}})])])]}}],null,false,124419732)})],1)],1),_c('div',{staticClass:"d-flex justify-content-start flex-wrap"},[(_vm.isEdit)?_c('div',[_c('Button',{attrs:{"clickHandler":function () { return _vm.$bvModal.show('delete-post-modal'); },"buttonVariant":"danger","classes":"form-button mr-1","buttonText":"Delete"}})],1):_vm._e(),_c('div',[_c('Button',{attrs:{"clickHandler":_vm.validateForm,"buttonVariant":"primary","classes":"form-button","isLoading":_vm.actionButtonLoader,"buttonText":_vm.submitButtonText}})],1)])],1):_vm._e(),_c('ConfirmModal',{attrs:{"id":"delete-post-modal","onYesPress":_vm.deletePost,"isLoading":_vm.deleteButtonLoader,"isSuccess":_vm.isPostDeleted,"onOkayPress":_vm.handleOkayPress,"messageText":"Are you sure you want to delete this post?","successText":"Post deleted successfully !"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }