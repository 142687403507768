var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-detail-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.fetching)?[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]:_vm._e(),(!_vm.fetching)?_c('b-card',{staticClass:"container-card"},[_c('div',{staticClass:"cover-image-container"},[_c('img',{staticClass:"cover-image",attrs:{"src":_vm.getMediaPath(_vm.tournamentData.CoverPhoto) ||
							require('../../assets/images/gallery/no-cover-image-placeholder.png')}})]),_c('div',{staticClass:"p-2"},[_c('div',[_c('b-card-title',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.tournamentData.Name || '-'))]),_c('b-row',[_c('b-col',{attrs:{"sm":"auto","lg":"8","md":"auto"}},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\td-flex\n\t\t\t\t\t\t\t\t\t\talign-items-center\n\t\t\t\t\t\t\t\t\t\tjustify-content-start\n\t\t\t\t\t\t\t\t\t\ttournament-location\n\t\t\t\t\t\t\t\t\t"},[_c('feather-icon',{staticClass:"ml-0 mr-1 cursor-pointer",attrs:{"icon":"MapPinIcon","size":"12"}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.tournamentData.LocationAddress || '-')+" ")])],1)]),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('div',[_c('LabelText',{attrs:{"label":"Entry Fees","text":((_vm.tournamentData.EntityFeeCurrency) + " " + (_vm.tournamentData.EntryFee)),"textClasses":"font-weight-bolder","containerClasses":"mb-8"}})],1)])],1)],1),_c('b-row',{staticClass:"pt-4 pb-4",attrs:{"col":"12"}},[_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Creator","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","avatarImage":_vm.getMediaPath(
											_vm.tournamentData.User.Media
										),"fullName":("" + (_vm.tournamentData.User.FullName || '-')),"containerClasses":"mb-8","nameClasses":"marginT"}})],1)],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Date Created","text":_vm.convertDateTime({
										date: _vm.tournamentData.CreatedAt,
										customFormat:
											_vm.dateTimeFormat.appDateFormat,
										dateOnly: true,
									}),"textClasses":"font-weight-bolder","containerClasses":"mb-8"}})],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Status","text":_vm.TournamentStatus.getDisplayTextKey(
										_vm.tournamentData.TournamentStatus
									) || '-',"textClasses":_vm.TournamentStatus.getLabelClass(
										_vm.tournamentData.TournamentStatus
									)}})],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Participants","textClasses":"font-weight-bolder","containerClasses":"mb-8","showRightSection":""}},[(_vm.totalTournamentParticipants > 0)?_c('div',[_c('GroupedAvatar',{attrs:{"id":"modal","likedByProfiles":_vm.tournamentParticipants || null,"onClick":_vm.handleParticipantsModal,"onViewClickText":(_vm.totalTournamentParticipants + " Participants")}})],1):_c('div',[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" No Participants ")])])])],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Starting From","text":_vm.handleGetTournamentDuration(),"textClasses":"font-weight-bolder","containerClasses":"mb-8"}})],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Total Posts","text":_vm.tournamentData.Group.PostsCount,"textClasses":"font-weight-bolder","containerClasses":"mb-8"}})],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-5 mb-5",attrs:{"col":"12"}},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('Title',{attrs:{"text":("" + (_vm.tournamentData.TournamentStatus ==
									_vm.TournamentStatus.Completed
										? 'Team Winners'
										: 'Winning Prizes'))}})],1),_c('b-col',{attrs:{"col":"12"}},[_c('Winning',{attrs:{"winnerConfig":_vm.winningData}})],1)],1),_c('hr'),_c('b-row',{attrs:{"col":"12"}},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('Title',{attrs:{"text":"Group Description"}})],1),_c('b-col',{attrs:{"col":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(
									_vm.handleConvertTextLinksToTag(
										_vm.tournamentData.Description
									)
								)}})])],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('div',{staticStyle:{"width":"200px"}},[_c('Button',{attrs:{"containerClasses":"w-100","buttonText":"Delete","buttonVariant":"danger","classes":"mt-1 mb-1 w-100","clickHandler":_vm.handleDeleteClick}})],1)])],1)],1),_c('ConfirmModal',{attrs:{"id":"delete-tournament-modal","isLoading":_vm.isProcessing,"isSuccess":_vm.deleteSuccess,"successText":"Tournament deleted successfully!","onOkayPress":_vm.handleOkayPress,"onYesPress":_vm.handleYesPress,"messageText":"Are you sure you want to delete this tournament?"}}),_c('BasicModal',{attrs:{"id":"group-members-modal","modalTitle":"Participants"},scopedSlots:_vm._u([{key:"showAllViewsUsers",fn:function(){return [_c('TournamentsParticipantsModal',{attrs:{"usersData":_vm.tournamentParticipants}})]},proxy:true}],null,false,3166089200)})],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }