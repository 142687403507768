<template>
	<b-card class="create-community-card">
		<template v-if="isLoading">
			<div class="text-center text-primary my-2">
				<b-spinner class="align-middle"></b-spinner>
			</div>
		</template>
		<validation-observer ref="validationRules" v-if="!isLoading">
			<b-row class="form-row">
				<b-col md="6" lg="6">
					<label class="label-text font-weight-bolder" for="title"
						>Title</label
					>
					<validation-provider
						#default="{ errors }"
						rules="required|min:3|max:100"
						name="Title"
					>
						<input-field
							id="title"
							v-model="title"
							:errors="errors"
						></input-field>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-col>
				<b-col md="6" lg="6">
					<label class="label-text font-weight-bolder" for="hashTag"
						>Hashtags</label
					>
					<validation-provider
						#default="{ errors }"
						rules="required"
						name="HashTag"
					>
						<b-form-tags
							v-model="hashtags"
							input-id="tags-basic"
							class="mb-2"
							separator=" ,;"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-col>
			</b-row>
			<b-row class="form-row">
				<b-col md="6" lg="6">
					<label class="label-text font-weight-bolder" for="category"
						>Category</label
					>
					<validation-provider
						#default="{ errors }"
						rules="required"
						name="Category"
					>
						<input-select
							:errors="errors"
							:options="getStatusOptions(communityPostCategory)"
							:clearable="false"
							:value="
								communityPostCategory.getDisplayTextKey(
									categoryId
								)
							"
							:onChange="onCategoryChange"
							placeholder="Select Category from List"
						></input-select>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-col>
			</b-row>
			<b-row class="form-row">
				<b-col md="12" lg="12">
					<div class="file-input">
						<div class="file-input-title">Featured Image</div>
						<div class="d-flex justify-content-start">
							<span
								v-for="(item, index) in imageSelectedPreviews"
								:key="'previous' + index"
							>
								<PreviewImage
									:imageToPreview="getMediaPath(item)"
									:crossHandler="() => removeImages(index)"
								/>
							</span>
							<span
								v-for="(item, index) in selectedFiles"
								:key="'selected' + index"
							>
								<PreviewImage
									:imageToPreview="item"
									:localFile="true"
									:crossHandler="
										() => emptyPreviewImages(index)
									"
								/>
							</span>
							<div
								v-if="
									imageSelectedPreviews.length +
										selectedFiles.length <
									4
								"
								class="file-input-uploader"
							>
								<div class="uploader">
									<FileInput
										:isRequired="true"
										:data="selectedFiles"
										:onChange="handleFileSelection"
										:multiple="true"
										placeholderText="Add Images"
									/>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row class="form-row editor">
				<b-col md="12" lg="12">
					<label class="label-text font-weight-bolder" for="quill"
						>Content</label
					>
					<validation-provider
						#default="{ errors }"
						rules="required|min:20"
						name="Content"
					>
						<div class="text-danger">{{ errors[0] }}</div>
						<quill-editor
							id="quill"
							v-model="content"
							:options="editorOption"
						>
							<div id="toolbar" slot="toolbar">
								<!-- Add a bold button -->
								<button class="ql-bold">Bold</button>
								<button class="ql-italic">Italic</button>
								<button class="ql-link">Link</button>

								<!-- Add font size dropdown -->
								<select class="ql-size">
									<option value="small" />
									<!-- Note a missing, thus falsy value, is used to reset to default -->
									<option selected />
									<option value="large" />
									<option value="huge" />
								</select>

								<select class="ql-font">
									<option selected="selected" />
									<option value="serif" />
									<option value="monospace" />
								</select>

								<!-- Add subscript and superscript buttons -->
								<button class="ql-script" value="sub" />
								<button class="ql-script" value="super" />
							</div>
						</quill-editor>
					</validation-provider>
				</b-col>
			</b-row>
			<div class="d-flex justify-content-start flex-wrap">
				<div v-if="isEdit">
					<Button
						:clickHandler="() => $bvModal.show('delete-post-modal')"
						buttonVariant="danger"
						classes="form-button mr-1"
						buttonText="Delete"
					></Button>
				</div>
				<div>
					<Button
						:clickHandler="validateForm"
						buttonVariant="primary"
						classes="form-button"
						:isLoading="actionButtonLoader"
						:buttonText="submitButtonText"
					></Button>
				</div>
			</div>
		</validation-observer>
		<ConfirmModal
			id="delete-post-modal"
			:onYesPress="deletePost"
			:isLoading="deleteButtonLoader"
			:isSuccess="isPostDeleted"
			:onOkayPress="handleOkayPress"
			messageText="Are you sure you want to delete this post?"
			successText="Post deleted successfully !"
		/>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { BRow, BCol, BCard, BFormTags, BSpinner } from 'bootstrap-vue'
import {
	required,
	email,
	confirmed,
	url,
	between,
	alpha,
	integer,
	password,
	min,
	digits,
	alphaDash,
	length,
	alphaNum,
	max,
	alphaSpaces,
	image,
	size,
} from '@validations'
import { computed, reactive, ref, toRefs, watch } from '@vue/composition-api'
import InputField from '../../components/Input.vue'
import InputSelect from '../../components/InputSelect.vue'
import Button from '../../components/Button.vue'
import FileInput from '../../components/FileInput.vue'
import PreviewImage from '../../components/PreviewImage.vue'
import { CommunityPostCategory } from '../../constants'
import { getFileSizeInMB, getStatusOptions } from '../../utils/commonUtils'
import {
	initializeMediaUtil,
	getMediaStream,
	uploadOnS3,
	finalizeMediaUtil,
	toBase64,
	getMediaPath,
	MAX_FILE_SIZE_ALLOWED_IN_MB,
} from '../../utils/mediaUtils'
import {
	createCommunityPost,
	deleteCommunityPost,
	getCommunityPostById,
	updateCommunityPost,
} from '@/api/api.service'
import showToast from '../../hooks/useToast'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'
export default {
	name: 'CommunityPostForm',
	components: {
		BRow,
		BCol,
		BCard,
		BFormTags,
		BSpinner,
		InputField,
		InputSelect,
		Button,
		FileInput,
		quillEditor,
		PreviewImage,
		ConfirmModal,
		ValidationProvider,
		ValidationObserver,
	},
	setup(props, context) {
		const postId = Number(context.root.$route.params?.id)
		const title = ref('')
		const hashtags = ref([])
		const selectedFiles = ref([])
		const imageSelectedPreviews = ref([])
		const categoryId = ref(null)
		const content = ref('')
		const isEdit = ref(false)
		const isLoading = ref(false)
		const actionButtonLoader = ref(false)
		const deleteButtonLoader = ref(false)
		const isPostDeleted = ref(false)

		if (postId) {
			isLoading.value = true
			isEdit.value = true
			getCommunityPostById(postId)
				.then((res) => {
					title.value = res.CommunityPost.Title
					categoryId.value = res.CommunityPost.CategoryId
					content.value = res.CommunityPost.Content
					hashtags.value = res.CommunityPost.Hashtag.map(
						(item) => item.Title
					)
					imageSelectedPreviews.value = res.CommunityPost.Media
				})
				.finally(() => {
					isLoading.value = false
				})
		}
		let submitButtonText = 'Add Post'
		if (isEdit.value) {
			submitButtonText = 'Save Changes'
		}

		async function validateForm() {
			let canSubmit = await this.$refs.validationRules.validate()
			if (!canSubmit) {
				return
			}

			if (
				!isEdit.value &&
				(!selectedFiles.value.length || selectedFiles.value.length > 4)
			) {
				showToast(context.root, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: !selectedFiles.length
						? 'Please select atleast one image'
						: 'Only 4 images are allowed',
					Variant: 'danger',
				})
				return
			}

			let totalSelectedImages =
				imageSelectedPreviews.value.length + selectedFiles.value.length

			if (
				isEdit.value &&
				(!totalSelectedImages || totalSelectedImages > 4)
			) {
				showToast(context.root, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: !totalSelectedImages
						? 'Please select atleast one image'
						: 'Only 4 images are allowed',
					Variant: 'danger',
				})
				return
			}
			if (selectedFiles.value.length) {
				let isImageSizesValid = true
				for (let selectedFile of selectedFiles.value) {
					if (
						getFileSizeInMB(selectedFile.size) >
						MAX_FILE_SIZE_ALLOWED_IN_MB
					) {
						isImageSizesValid = false
						break
					}
				}
				if (!isImageSizesValid) {
					showToast(context.root, {
						Title: 'Error',
						Icon: 'XCircleIcon',
						Text: `Maximum ${MAX_FILE_SIZE_ALLOWED_IN_MB} mb size media is allowed`,
						Variant: 'danger',
					})
					return
				}
			}

			actionButtonLoader.value = true

			let MediaIds = []

			if (selectedFiles.value.length) {
				const mediaData = await getMediaData()
				MediaIds = mediaData.map((media) => media.Id)
			}

			if (imageSelectedPreviews.value.length) {
				MediaIds = MediaIds.concat(
					imageSelectedPreviews.value.map((media) => media.Id)
				)
			}

			let bodyData = {
				Title: title.value,
				Content: content.value,
				MediaId: MediaIds,
				Hashtags: hashtags.value,
				CategoryId: categoryId.value,
			}
			try {
				if (isEdit.value) {
					await updateCommunityPost(postId, bodyData)
					actionButtonLoader.value = false
					showToast(context.root, {
						Title: 'Update Successfully',
						Icon: 'CheckCircleIcon',
					})
				} else {
					await createCommunityPost(bodyData)
					actionButtonLoader.value = false
					showToast(context.root, {
						Title: 'Added Successfully',
						Icon: 'CheckCircleIcon',
					})
				}
				context.root.$router.push(APP_ROUTES.COMMUNITY_POSTS)
			} catch (e) {
				actionButtonLoader.value = false
				showToast(context.root, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: e,
					Variant: 'danger',
				})
			}
		}

		function onCategoryChange(obj) {
			categoryId.value = obj.value
		}

		function handleFileSelection(val) {
			let fileLimit = 4
			if (imageSelectedPreviews.value.length) {
				fileLimit = fileLimit - imageSelectedPreviews.value.length
			}

			if (selectedFiles.value.length < fileLimit) {
				selectedFiles.value = selectedFiles.value.concat(val)
				if (selectedFiles.value.length > fileLimit) {
					selectedFiles.value = selectedFiles.value.filter(
						(item, index) => index < fileLimit
					)
				}
			}
		}

		async function deletePost() {
			try {
				deleteButtonLoader.value = true
				await deleteCommunityPost(postId)
				deleteButtonLoader.value = false
				showToast(context.root, {
					Title: 'Deleted Successfully',
					Icon: 'CheckCircleIcon',
				})
				isPostDeleted.value = true
				context.root.$router.push(APP_ROUTES.COMMUNITY_POSTS)
			} catch (e) {
				deleteButtonLoader.value = false
				showToast(context.root, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: e,
					Variant: 'danger',
				})
			} finally {
				deleteButtonLoader.value = false
			}
		}

		const getMediaData = async () => {
			if (selectedFiles.value.length) {
				let imageResponses = selectedFiles.value.map((file) => {
					return uploadMedia(file)
				})
				let filesArray = (await Promise.allSettled(imageResponses))
					.filter((item) => item.status === 'fulfilled')
					.map((val) => val.value)
				return Promise.resolve(filesArray)
			}
		}

		const uploadMedia = async (file) => {
			let credentialsRequest = initializeMediaUtil(file)
			let mediaStreamRequest = getMediaStream(file)
			const [credentials, mediaStream] = await Promise.all([
				credentialsRequest,
				mediaStreamRequest,
			])
			await uploadOnS3(mediaStream, credentials, (progress) => {
				progress.value = progress
			})
			let finalizeRes = await finalizeMediaUtil(credentials?.MediaId)

			return finalizeRes?.Media
		}

		const setProgress = (val) => {
			progress.value = val
		}

		const emptyPreviewImages = (index) => {
			selectedFiles.value = selectedFiles.value.filter(
				(item, i) => i !== index
			)
		}

		const removeImages = (index) => {
			imageSelectedPreviews.value = imageSelectedPreviews.value.filter(
				(item, i) => i !== index
			)
		}
		const handleOkayPress = () => {
			isPostDeleted.value = false
		}
		return {
			communityPostCategory: CommunityPostCategory,
			getStatusOptions,
			onCategoryChange,
			editorOption: {
				modules: {
					toolbar: '#toolbar',
				},
				formats: ['bold', 'underline', 'header', 'italic', 'link'],
			},
			title,
			category: '',
			categoryId,
			content,
			hashTag: '',
			hashtags,
			required,
			email,
			confirmed,
			url,
			between,
			alpha,
			integer,
			password,
			min,
			digits,
			alphaDash,
			length,
			alphaNum,
			max,
			alphaSpaces,
			image,
			size,
			validateForm,
			selectedFiles,
			toBase64,
			emptyPreviewImages,
			isEdit,
			submitButtonText,
			deletePost,
			getMediaPath,
			imageSelectedPreviews,
			removeImages,
			isLoading,
			actionButtonLoader,
			deleteButtonLoader,
			handleFileSelection,
			handleOkayPress,
		}
	},
}
</script>

<style scoped>
.label-text {
	color: black;
}

.form-row {
	margin-bottom: 20px;
}

.file-input {
	border: 1px solid #d8d6de;
	padding: 20px;
	border-radius: 0.357rem;
}

.file-input-title {
	font-size: 18px;
	font-weight: 600;
	color: black;
	margin-bottom: 20px;
}

.file-input-sub-title {
	color: #b9b9c3;
	font-size: 14px;
	margin-bottom: 20px;
}

.file-input-uploader {
	max-width: 231px;
	cursor: pointer;
}

.editor {
	min-height: 270px;
}

.uploader {
	position: absolute;
	bottom: 30px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>