import {
  deleteModeration,
  getModerationListing,
  updateModeration,
} from "../../api/api.service";
import config from "../../config";
export default {
  namespaced: true,
  state: {
    fetching: false,
    list: [],
    totalReports: 0,
  },
  getters: {
    getModerationById: (state) => (id) =>
      state.list.find((report) => report.Id == id),
    getModerationList: (state) => {
      return state.list.map((item) => {
        let Title = null;
        let Seller = {};
        let Type = "";
        if (item.GuideTrip) {
          Title = item.GuideTrip.Title;
          Seller = item.GuideTrip.User;
          Type = "Fishing Trip";
        } else if (item.Location) {
          Title = item.Location.Title || item.Location.Address;
          Seller = item.Location.User;
          Type = "Fishing Spot";
        } else if (item.UserStory) {
          Title = "-";
          Seller = item.UserStory.User;
          Type = "User Story";
        }else if (item.UserStoryTag) {
          Title = "-";
          Seller = item.UserStoryTag.User;
          Type = "User Story Comment";
        }
         else {
          Title = item?.Gear?.Name||"-";
          Seller = item?.Gear?.User;
          Type = "Fishing Gear";
        }
        return { ...item, Title, Seller: Seller || {}, Type };
      });
    },
  },
  actions: {
    async getModerationListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("moderationListRequest");
      try {
        let response = await getModerationListing(queryParams, signal);
        commit("moderationListSuccess", {
          payload: response.Reports,
          totalReports: response.TotalReports,
        });
      } catch (err) {
        commit("moderationListFail");
      }
    },

    async updateModerationStatus({ commit }, { id, status }) {
      try {
        let response = await updateModeration(id, { Status: status });
        commit("moderationUpdateSuccess", {
          id,
          status,
        });
      } catch (err) {
        alert(err);
      }
    },

    async deleteModeration({ commit }, { id }) {
      try {
        let response = await deleteModeration(id);
        commit("moderationDeleteSuccess", {
          id,
        });
      } catch (err) {
        alert(err);
      }
    },
  },
  mutations: {
    moderationListRequest: (state) => {
      state.fetching = true;
      state.list = [];
    },
    moderationListSuccess: (state, action) => {
      state.fetching = false;
      state.list = action.payload;
      state.totalReports = action.totalReports;
    },
    moderationListFail: (state, payload) => {
      state.fetching = false;
    },
    moderationUpdateSuccess: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.Id === action.id) {
          item.Status = action.status;
        }
        return item;
      });
    },

    moderationDeleteSuccess: (state, action) => {
      state.list = state.list.filter((item) => {
        return item.Id !== action.id;
      });
    },
  },
};
