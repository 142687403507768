<template>
  <div class="mt-2">
    <b-card body-class="card-body-style p-0">
      <div class="p-1">
        <search-header
          :showSearchField="false"
          :showStatusFilter="false"
          :pageLimitValue="pageLimit"
          :onPageLimitChange="handlePageLimitChange"
          :selectedPageValue="page"
          :perPage="pageLimit"
          :onHandlePageChange="handlePageChange"
          classes="margin-bottom-only"
        >
          <template #left-header-content>
            <span class="search-label mr-1">Search</span>
            <b-form-input
              class="d-inline-block w-25"
              @input="handleSearchChange"
              :value="searchText"
            />
          </template>
          <template #right-header-content>
            <Button
              buttonVariant="outline-primary"
              buttonText="+ Add New Gear"
              :clickHandler="handleAddGear"
            />
          </template>
        </search-header>
      </div>

      <data-table
        :perPageLimit="pageLimit"
        entriesName="Gears"
        :showPagination="true"
        :config="config"
        :data="data"
        :totalRecords="total"
        :isLoading="isFetching"
        :selectedPageValue="page"
        :perPage="data.length"
        :onHandlePageChange="handlePageChange"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(No)="data">
          <span class="font-weight-bolder">{{ data.item.Id }}</span>
        </template>

        <template #cell(Categories)="data">
          <span class="font-weight-bolder">{{
            data.item.GearCategory.Name || "-"
          }}</span>
        </template>

        <template #cell(GearName)="data">
          <span class="font-weight-bolder">{{ data.item.Name || "-" }}</span>
        </template>

        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-b-tooltip.hover.top="'Edit'"
              icon="EditIcon"
              size="16"
              class="mr-3 detail-icon cursor-pointer"
              v-b-modal="modalId"
              v-on:click="handleEditClick(data)"
            />

            <feather-icon
              v-b-tooltip.hover.top="'Delete'"
              :icon="`Trash2Icon`"
              class="delete-icon cursor-pointer"
              size="16"
              v-b-modal="'delete-modal'"
              @click="handleDeleteClick(data.item.Id)"
            />
          </div>
        </template>
      </data-table>
    </b-card>
    <BasicModal
      id="modal-1"
      :modalTitle="isEdit ? 'Edit Fishing Gear' : 'Add New Fishing Gear'"
    >
      <template #showForm>
        <div>
          <b-form-group
            label-cols="4"
            label="Gear Name:"
            label-for="gear-name"
            label-size="md"
            class="mt-1"
          >
            <Input
              placeholder="Type Gear Name"
              id="gear-name"
              v-model="gearName"
              size="md"
              classes="w-100 font-weight-bolder"
            />
          </b-form-group>
          <b-form-group
            label-cols="4"
            label="Select Category:"
            label-for="input-default"
            label-size="md"
            class="mt-1"
          >
            <Select
              :options="categoryOptions"
              classes="fullWidth"
              :onScrollToBottom="handleScrollToBottom"
              :isFetching="categoriesFetching"
              :selectedOption="selectedCategory"
              :onChange="handleCategoryChange"
            />
          </b-form-group>
          <div class="text-right">
            <Button
              buttonVariant="primary"
              :buttonText="isEdit ? 'Submit' : 'Add Gear'"
              classes="w-50 mb-2"
              :isLoading="isLoading"
              :clickHandler="handleSubmit"
            />
          </div>
        </div>
      </template>
    </BasicModal>
    <ConfirmModal
      id="delete-modal"
      :isLoading="isLoading"
      :isSuccess="deleteSuccess"
      successText="Gear Deleted Successfully !"
      :onOkayPress="handleOkayPress"
      :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this gear?"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
  BFormGroup,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, reactive } from "@vue/composition-api";

import DataTable from "../../../components/data-table/DataTable.vue";
import SearchHeader from "../../../components/SearchHeader.vue";
import Button from "../../../components/Button.vue";
import BasicModal from "../../../components/BasicModal.vue";
import ConfirmModal from "../../../components/ConfirmModal.vue";
import Input from "../../../components/Input.vue";
import Select from "../../../components/Select.vue";
import {
  getGearListing,
  deleteGear,
  getGearCategoryList,
  updateGear,
  createGear,
} from "../../../api/api.service";
import { FishGearTypes, SortOrder } from "../../../constants";
import { useLocalPagination } from "../../../hooks/useLocalPagination";
import Config from "../../../config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const config = [
  {
    label: "No",
    key: "No",
    tdClass: "table-row",
    thClass: "table-header",
  },
  {
    label: "CATEGORIES",
    key: "Categories",
    tdClass: "table-row",
    thClass: "table-header",
    isSortable: true,
  },
  {
    label: "GEAR NAME",
    tdClass: "table-row",
    thClass: "table-header",
    key: "GearName",
  },
  {
    label: "ACTIONS",
    tdClass: "table-row",
    thClass: "table-header",
    key: "Actions",
  },
];

export default {
  name: "FishingGearsList",
  components: {
    DataTable,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    SearchHeader,
    BFormInput,
    Button,
    BFormGroup,
    BasicModal,
    Input,
    Select,
    VBTooltip,
    ConfirmModal,
  },

  setup() {
    const gearName = ref("");
    const selectedCategory = reactive({ id: null, title: null });
    const gearId = ref(null);
    const isEdit = ref(false);
    const isLoading = ref(false);
    const deleteSuccess = ref(false);
    const categories = ref([]);
    const totalCategories = ref(0);
    const categoriesFetching = ref(false);
    const categoryPage = ref(1);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSortChange,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      request,
    } = useLocalPagination(
      getGearListing,
      () => ({
        Direction: SortOrder.DESC,
        Column: "CreatedAt",
        Type: FishGearTypes.NORMAL,
      }),
      [],
      "Name",
      "Gears",
      "TotalGears"
    );

    const getCategories = () => {
      categoriesFetching.value = true;
      getGearCategoryList({ Page: categoryPage.value, Limit: Config.LIMIT })
        .then((res) => {
          let data = res.GearCategories.map((item) => {
            return {
              ...item,
              title: item.Name,
              id: item.Id,
            };
          });
          categories.value =
            categoryPage.value == 1 ? data : [...categories.value, ...data];
          totalCategories.value = res.TotalGearCategories;
        })
        .catch((err) => {})
        .finally(() => {
          categoriesFetching.value = false;
        });
    };

    onMounted(() => {
      getCategories();
    });

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    function handleAddGear() {
      gearId.value = null;
      gearName.value = "";
      isEdit.value = false;
      Object.keys(selectedCategory).forEach(
        (key) => delete selectedCategory[key]
      );

      this.$bvModal.show("modal-1");
    }

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    const handleScrollToBottom = () => {
      if (
        categories.value.length < totalCategories.value &&
        !categoriesFetching.value
      ) {
        categoryPage.value = categoryPage.value + 1;
        getCategories();
      }
    };

    const handleYesPress = () => {
      isLoading.value = true;

      deleteGear(gearId.value)
        .then((res) => {
          data.value = data.value.filter((item) => item.Id !== gearId.value);

          total.value = total.value - 1;
          deleteSuccess.value = true;

          if (total.value && !Boolean(data.value.length)) {
            page.value = page.value - 1;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const handleEditClick = (data) => {
      isEdit.value = true;
      gearId.value = data.item.Id;
      gearName.value = data.item.Name;
      Object.assign(selectedCategory, {
        id: data.item.GearCategory.Id,
        title: data.item.GearCategory.Name,
        ...data.item.GearCategory,
      });
    };

    const handleCategoryChange = (val) => {
      Object.assign(selectedCategory, val);
    };

    const handleDeleteClick = (id) => {
      gearId.value = id;
    };

    const handleOkayPress = () => {
      deleteSuccess.value = false;
      gearId.value = null;
    };

    function handleSubmit() {
      let payload = {
        Name: gearName.value,
        GearCategoryId: selectedCategory.Id,
        Type: FishGearTypes.NORMAL,
      };

      let requestFunc = isEdit.value
        ? updateGear(gearId.value, payload)
        : createGear(payload);

      isLoading.value = true;

      requestFunc
        .then(() => {
          this.$bvModal.hide("modal-1");

          if (isEdit.value) {
            data.value = data.value.map((item) => {
              return {
                ...item,
                Name: item.Id === gearId.value ? gearName.value : item.Name,
                GearCategory:
                  item.Id === gearId.value
                    ? selectedCategory
                    : item.GearCategory,
              };
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Gear Updated",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          } else {
            if (page.value !== 1) {
              page.value = 1;
            } else {
              request();
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Gear Created Successfully",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.Message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      modalId: "modal-1",
      config,
      selected: { title: "Select From List" },
      categoryOptions: categories,
      gearName,
      gearId,
      isEdit,
      isLoading,
      deleteSuccess,
      data,
      isFetching,
      total,
      searchText,
      page,
      pageLimit,
      handleAddGear,
      handleSearchChange,
      handlePageChange,
      handlePageLimitChange,
      handleScrollToBottom,
      handleYesPress,
      handleOkayPress,
      handleDeleteClick,
      handleEditClick,
      categoriesFetching,
      selectedCategory,
      handleCategoryChange,
      handleSubmit,
      sortOrder,
      sortColumn,
      handleSortChange,
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.delete-icon {
  color: red;
}

.detail-icon {
  color: #F78F21;
}
::v-deep .table-row {
  font-weight: bold;
}
/* ::v-deep .table-header {
  width: 30%;
} */
</style>
