<template>
  <div>
    <b-img
      v-for="(item, index) in galleryConfig"
      :key="index"
      class="d-inline-block mr-1 mb-1"
      :width="imageWidth"
      :height="imageHeight"
      :src="`${item.BasePath}${item.ThumbPath}`"
    />
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";

import Img1 from "@/assets/images/gallery/image-02.png";

export default {
  name: "ImageGallery",
  components: {
    BImg,
  },
  props: {
    galleryConfig: {
      type: Array,
      default: [
        {
          classes: "d-inline-block mr-1 mb-1",
          height: "120",
          width: "",
          source: Img1,
        },
        {
          classes: "d-inline-block mr-1 mb-1",
          height: "120",
          width: "",
          source: require("@/assets/images/gallery/image-02.png"),
        },
        {
          classes: "d-inline-block mr-1 mb-1",
          height: "120",
          width: "",
          source: require("@/assets/images/gallery/image-03.png"),
        },
        {
          classes: "d-inline-block mr-1 mb-1",
          height: "120",
          width: "",
          source: require("@/assets/images/gallery/image-04.png"),
        },
      ],
    },
    imageWidth: {
      type: String,
      default: "120",
    },
    imageHeight: {
      type: String,
      default: "120",
    },
  },
};
</script>

<style>
</style>