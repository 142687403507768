var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog-detail-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.fetching)?[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]:_vm._e(),(!_vm.fetching)?_c('b-card',{staticClass:"container-card"},[_c('div',{staticClass:"cover-image-container"},[_c('img',{staticClass:"cover-image",attrs:{"src":_vm.getMediaPath(_vm.groupData.CoverPhoto) ||
							require('../../assets/images/gallery/no-cover-image-placeholder.png')}})]),_c('div',{staticClass:"p-2"},[_c('div',[_c('b-card-title',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.groupData.Name))]),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\td-flex\n\t\t\t\t\t\t\t\talign-items-center\n\t\t\t\t\t\t\t\tjustify-content-start\n\t\t\t\t\t\t\t\tgroup-status\n\t\t\t\t\t\t\t"},[_c('feather-icon',{staticClass:"ml-0 mr-1 cursor-pointer",attrs:{"id":"locker-icon","icon":("" + (_vm.groupData.Visibility ==
									_vm.GroupsVisibility.PUBLIC
										? 'GlobeIcon'
										: 'LockIcon')),"size":"14"}}),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(((_vm.GroupsVisibility.getDisplayTextKey( _vm.groupData.Visibility )) + " Group"))+" ")])],1)],1),_c('b-row',{staticClass:"pt-4 pb-4",attrs:{"col":"12"}},[_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Admin","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","avatarImage":_vm.getMediaPath(
											_vm.groupData.AdminUser.Media
										),"fullName":("" + (_vm.groupData.AdminUser.FullName || '-'))}})],1)],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Date Created","text":_vm.convertDateTime({
										date: _vm.groupData.CreatedAt,
										customFormat:
											_vm.dateTimeFormat.appDateFormat,
										dateOnly: true,
									})}})],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Tournaments","text":"-","textClasses":"font-bold","containerClasses":"mb-8"}})],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Members","textClasses":"font-bold","showRightSection":""}},[(_vm.totalGroupMembers > 0)?_c('div',{staticClass:"mb-1"},[_c('GroupedAvatar',{attrs:{"id":"modal","likedByProfiles":_vm.groupMembers.length < 5
												? _vm.groupMembers
												: _vm.groupMembers.slice(0, 5),"onClick":_vm.handleGroupMembersModal}})],1):_c('div',[_c('p',{staticClass:"font-weight-normal"},[_vm._v(" No Members ")])])])],1),_c('b-col',{attrs:{"sm":"auto","lg":"4","md":"auto"}},[_c('LabelText',{attrs:{"label":"Total Posts","text":_vm.groupData.PostsCount || '-',"textClasses":"font-bold","containerClasses":("" + (_vm.groupMembers.length > 0 && 'mt-1'))}})],1)],1),_c('hr'),_c('b-row',{attrs:{"col":"12"}},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('Title',{attrs:{"text":"Group Description"}})],1),_c('b-col',{attrs:{"col":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(
									("<p>\n\t\t\t\t\t\t\t\t\t" + (_vm.handleConvertTextLinksToTag(_vm.groupData.Description)) + "</p>")
								)}})])],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('div',{staticStyle:{"width":"200px"}},[_c('Button',{attrs:{"containerClasses":"w-100","buttonText":"Delete","buttonVariant":"danger","classes":"mt-1 mb-1 w-100","clickHandler":_vm.handleDeleteClick}})],1)])],1)],1),_c('ConfirmModal',{attrs:{"id":"delete-group-modal","isLoading":_vm.isProcessing,"isSuccess":_vm.deleteSuccess,"successText":"Group deleted successfully!","onOkayPress":_vm.handleOkayPress,"onYesPress":_vm.handleYesPress,"messageText":"Are you sure you want to delete this group?"}}),_c('BasicModal',{attrs:{"id":"group-members-modal","modalTitle":"Group Members"},scopedSlots:_vm._u([{key:"showAllViewsUsers",fn:function(){return [_c('GroupMembersModal',{attrs:{"usersData":_vm.groupMembers}})]},proxy:true}],null,false,973144899)})],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }