<template>
  <div>
    <b-card body-class="card-body-style">
      <!-- :statusOptions="getStatusOptions(purchaseType)"
        :filterValue="filters.Status"
        :onFilterChange="handleFilterChange" -->
      <SearchHeader
        :showStatusFilter="false"
        :showSearchField="false"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimitChange"
      >
        <!-- Left side of header -->
        <template v-slot:left-header-content>
          <span class="search-label mr-2 search-text">Search</span>
          <b-form-input
            @input="handleSearchChange"
            :value="searchText"
            class="d-inline-block mr-3 search-field"
          />
        </template>
        <!-- Right side of header -->
        <!-- <template v-slot:right-header-content>
          <input-select
            :options="perPageMonthOptions"
            :clearable="false"
            placeholder="Select Month"
            classes="month-selector d-inline-block ml-50 mr-1"
            :showLabel="true"
            :onChange="monthChangeHandler"
            :value="selectedMonth"
          />
          <PhotoSelectedText
            text="Total Earning this Month"
            :count="`$ ${numberFormatter(Number(15430), 1)}`"
          />
        </template> -->
      </SearchHeader>
      <DataTable
        :perPageLimit="pageLimit"
        entriesName="Reports"
        :isLoading="isFetching"
        :config="config"
        :data="data"
        :perPage="data.length"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageChange"
        :totalRecords="total"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(No)="data">
          <span class="font-weight-bolder">
            {{ data.item.Id }}
          </span>
        </template>

        <template #cell(UserName)="data">
          <span class="text-nowrap">
            <user-info
              iconSize="md"
              :fullName="data.item.User.FullName || '-'"
              :avatarImage="getMediaPath(data.item.User.Media)"
              nameClasses="black"
            />
          </span>
        </template>

        <template #cell(Loc)="data">
          <div class="font-weight-bolder" v-if="data.item.Location">
            <p>
              {{
                data.item.Location.Country ? data.item.Location.Country : "-"
              }}
            </p>
          </div>
          <div class="font-weight-bolder" v-else-if="data.item.GuideTrip">
            <p>
              {{
                data.item.GuideTrip
                  ? data.item.GuideTrip.Guide.CountryCodeAlpha
                  : "-"
              }}
            </p>
          </div>
        </template>
        <template #cell(PurchaseDate)="data">
          <span class="purchase-date">
            {{
              convertDateTime({
                date: data.item.PurchaseDate,
                customFormat: dateTimeFormat.appDateFormat,
                dateOnly: true,
              })
            }}
          </span>
        </template>

        <template #cell(EntityType)="data">
          <span class="category">
            {{ purchaseType.getDisplayTextKey(data.item.EntityType) || "-" }}
          </span>
        </template>

        <template #cell(TotalAmount)="data">
          <span class="price">$ {{ data.item.TotalAmount || "-" }} </span>
        </template>

        <template #cell(TransactionFee)="data">
          <span class="price">$ {{ data.item.FishFinFee || "-" }} </span>
        </template>
      </DataTable>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";

import DataTable from "@/components/data-table/DataTable.vue";
import UserInfo from "@/components/UserInfo.vue";
import SearchHeader from "@/components/SearchHeader.vue";
import InputSelect from "@/components/InputSelect.vue";
import PhotoSelectedText from "@/components/PhotoSelectedText.vue";
import { purchaseType } from "@/constants";
import { dateTimeFormat, convertDateTime } from "@/utils/dateFormatUtils";
import { getMediaPath, getStatusOptions } from "@/utils/commonUtils";
import { useLocalPagination } from "@/hooks/useLocalPagination";
import { getOrdersLisiting } from "@/api/api.service";
import { numberFormatter } from "../../utils/formatNumber";
const config = [
  {
    label: "No",
    key: "No",
  },

  {
    label: "USER NAME",
    key: "UserName",
  },
  {
    label: "Location",
    key: "Loc",
  },
  {
    label: "DATE",
    key: "PurchaseDate",
    isSortable: true,
  },
  {
    label: "PURCHASE TYPE",
    key: "EntityType",
    isSortable: true,
  },

  {
    label: "Price",
    key: "TotalAmount",
    isSortable: true,
  },
  {
    label: "TRANSACTION FEE",
    key: "TransactionFee",
  },
].map((item) => {
  return { ...item, tdClass: "table-row-medium-text" };
});

export default {
  name: "TransactionReport",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    BFormInput,
    DataTable,
    UserInfo,
    SearchHeader,
    InputSelect,
    PhotoSelectedText,
  },

  setup(props, context) {
    const isLoading = ref(false);
    const isSuccess = ref(false);
    const isError = ref(false);
    const selectedMonth = ref(null);
    const perPageOptions = ref([2020, 2019, 2018, 2017, 2016]);
    const perPageMonthOptions = ref([
      "October 2021",
      "Novemeber 2021",
      "December 2021",
    ]);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = useLocalPagination(
      getOrdersLisiting,
      () => ({}),
      [],
      "Q",
      "Transactions",
      "TotalTransactions"
    );
    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    const monthChangeHandler = (value) => {
      selectedMonth.value = value;
    };
    return {
      perPageOptions,
      perPageMonthOptions,
      config,
      data,
      isLoading,
      isSuccess,
      config,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      isError,
      dateTimeFormat,
      purchaseType,
      selectedMonth,
      monthChangeHandler,
      handlePageChange,
      handlePageLimitChange,
      convertDateTime,
      getMediaPath,
      getStatusOptions,
      handleSearchChange,
      handleSortChange,
      numberFormatter,
    };
  },
};
</script>

<style scoped>
.card-body-style {
  padding: 0 !important;
}

::v-deep .table-row-medium-text {
  font-weight: 500;
  color: black;
}

::v-deep .month-selector {
  width: 190px !important;
}

::v-deep .search-field {
  width: 170px !important;
}

::v-deep .search-text {
  margin-left: 2px !important;
}

.amount-text {
  color: #003071;
  margin-left: 10px !important;
}

.amount-description {
  margin-left: 10px !important;
  margin-right: 4px !important;
}

.earning-card {
  padding: 6px 6px !important;
  border: 1px solid #F78F21 !important;
  border-radius: 0.5rem !important;
  background-color: #FFF2E3;
}
</style>