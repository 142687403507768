<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:statusOptions="getStatusOptions(plan)"
				:filterValue="filters.Plan"
				selectPlaceholder="Select Plan"
				:onFilterChange="handleFilterChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
			></search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Users"
				:perPage="data.length"
				:isLoading="fetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:onSortClick="handleSortChange"
				:totalRecords="totalUsers"
			>
				<!-- Column: Id -->
				<template #cell(Id)="data">
					<span class="font-weight-bolder"> #{{ data.value }} </span>
				</template>

				<template #cell(FullName)="data">
					<span class="text-nowrap">
						<user-info
							iconSize="md"
							:fullName="data.item.firstName+' '+data.item.lastName"
							:subText="data.item.email"
						/>
					</span>
				</template>

				<!-- Column: Issued Date -->
				<template #cell(RegistrationDate)="data">
					<span class="text-nowrap">
						{{ data.value }}
					</span>
				</template>

				<template #cell(PhoneNumber)="data">
					<span class="text-nowrap">
						{{ data.item.CountryCode }}{{ data.item.PhoneNumber }}
					</span>
				</template>

					<template #cell(subdomain)="data">
					<a   href="javascript:void(0)" @click="openLink(data.value)" class="text-nowrap">
						{{ data.value }}
					</a>
				</template>

				<!-- Column: Registered On -->
				<template #cell(CreatedAt)="data">
					<span class="text-nowrap">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<template>
						<b-badge
							pill
							:variant="userStatusCode.getLabelClass(data.value)"
						>
							{{ userStatusCode.getDisplayTextKey(data.value) }}
						</b-badge>
					</template>
				</template>

					<template #cell(Plan)="data">
					<template>
						<b-badge
							pill
							:variant="plan.getLabelClass(data.item.planId)"
						>
							{{ plan.getDisplayTextKey(data.item.planId) }}
						</b-badge>
					</template>
				</template>


					<template #cell(subscriptionStatus)="data">
					<template>
						<b-badge
							pill
							:variant="SubscriptionStatus.getLabelClass(data.value)"
						>
							{{ SubscriptionStatus.getDisplayTextKey(data.value) }}
						</b-badge>
					</template>
				</template>

				<!-- Column: Actions -->
				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`user-list-row-${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item.id)"
						/>
						<b-tooltip
							title="Details"
							:target="`user-list-row-${data.item.Id}-detail-icon`"
						/>
						<!-- <feather-icon
							v-b-modal="`action-${data.item.Id}-modal`"
							v-if="
								[
									userStatusCode.VERIFIED,
									userStatusCode.SUSPENDED,
								].includes(data.item.Status)
							"
							:id="`user-list-row-${data.item.Id}-action-icon`"
							:icon="`${
								data.item.Status == userStatusCode.VERIFIED
									? 'SlashIcon'
									: 'CheckCircleIcon'
							}`"
							:class="`cursor-pointer ${
								data.item.Status == userStatusCode.VERIFIED
									? 'inactive-icon'
									: 'active-icon'
							}`"
							size="16"
						/>
						<b-tooltip
							:title="`${
								data.item.Status == userStatusCode.VERIFIED
									? 'Suspend'
									: 'Active'
							}`"
							class="cursor-pointer"
							:target="`user-list-row-${data.item.Id}-action-icon`"
						/> -->
					</div>
					<ConfirmModal
						:isLoading="isLoading"
						:isSuccess="isUserStatusChangedSuccess"
						:onOkayPress="handleOkayPress"
						successText="Success !"
						:onYesPress="
							() => {
								updateUserStatus(data.item.Id, data.item.Status)
							}
						"
						:id="`action-${data.item.Id}-modal`"
						:messageText="
							data.item.Status == userStatusCode.SUSPENDED
								? 'Are you sure you want to activate this user?'
								: 'Are you sure you want to suspend this user?'
						"
						:messageIcon="
							require('@/assets/images/icons/restrict-icon.svg')
						"
					/>
				</template>
			</data-table>
			<!-- `</b-card-body>` -->
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { computed, reactive, ref } from '@vue/composition-api'

import ConfirmModal from '../../components/ConfirmModal.vue'
import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'

import CrownIcon from '../../assets/images/icons/crown-icon.svg'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { getMediaPath, getStatusOptions } from '../../utils/commonUtils'
import { useStore } from '../../store'
import { usePaginatedRequest } from '../../hooks/usePaginatedRequest'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { UserStatusCode, SortOrder,Plan,SubscriptionStatus } from '../../constants'

export default {
	name: 'UserListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
	},
	setup(props, context) {
		const store = useStore()
		let crownIcon = CrownIcon
		const filters = reactive({
			Plan: null,
		})

		const isLoading = ref(false)
		const isUserStatusChangedSuccess = ref(false)

		let config = [
			{
				label: 'FULL NAME',
				key: 'FullName',
			},
			{
				label: 'ADDRESS',
				key: 'address',
			},
			{
				label: 'COMPANY',
				key: 'company',
			},
			{
				label: 'SUB-DOMAIN',
				key: 'subdomain',
			},
			{
				label: 'PLAN',
				key: 'Plan',
				isSortable: true,
			},
			{
				label: 'STATUS',
				key: 'status',
		//		isSortable: true,
			},
			{
				label: 'SUBSCRIPTION',
				key: 'subscriptionStatus',
				//isSortable: true,
			},
			{
				label: 'ACTIONS',
				key: 'Actions',
			},
		]
		let fetching = computed(() => store.state.user.fetching)
		let data = computed(() => store.state.user.list)
		let totalUsers = computed(() => store.state.user.totalUsers)

		const {
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageLimit,
			handlePageClick,
			handleSortChange,
		} = usePaginatedRequest(
			'user/getUserListAction',
			() => ({
				// direction: SortOrder.DESC,
				// column: 'CreatedAt',
				...(filters.Plan && { Plan: filters.Plan.value }),
			}),
			[filters]
		)

		function viewDetails(id) {
			this.$router.push(getRoute(APP_ROUTES.USER_DETAILS, { userId: id }))
		}

		function handleFilterChange(val) {
			handlePageClick(1)
			filters.Plan = val
		}

		function updateUserStatus(id, status) {
			isLoading.value = true
			let updatedStatus =
				status === UserStatusCode.VERIFIED
					? UserStatusCode.SUSPENDED
					: UserStatusCode.VERIFIED
			store
				.dispatch('user/updateUserStatus', {
					id: id,
					status: updatedStatus,
				})
				.then((res) => {
					isUserStatusChangedSuccess.value = true
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		function handleOkayPress() {
			isUserStatusChangedSuccess.value = false
		}

		function openLink(domain){
			window.open('https://'+domain+'.ubnotify.app')
		}

		return {
			openLink,
			config,
			dateTimeFormat,
			userStatusCode: UserStatusCode,
			plan:Plan,
			convertDateTime,
			data,
			fetching,
			totalUsers,
			viewDetails,
			searchText,
			page,
			pageLimit,
			crownIcon,
			filters,
			getMediaPath,
			getStatusOptions,
			handleSearchChange,
			handlePageClick,
			handleFilterChange,
			handlePageLimit,
			updateUserStatus,
			handleOkayPress,
			isUserStatusChangedSuccess,
			isLoading,
			handleSortChange,
			sortOrder,
			sortColumn,
			SubscriptionStatus
		}
	},
}
</script>

<style scoped>
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}
.subscription-icon {
	width: 14px;
	height: 14px;
	margin-right: 10px;
}
.card-body-style {
	padding: 0 !important;
}
.table-container {
	margin-top: -10px;
}
</style>
