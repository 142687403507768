<template>
	<div>
		<b-card class="w-100 card-container">
			<b-row>
				<b-col
					cols="12"
					lg="6"
					md="6"
					sm="12"
					class="d-flex align-items-center"
				>
					<b-card-title class="text-secondary font-weight-bolder">
						Earning Summary
					</b-card-title>
				</b-col>
				<b-col
					cols="12"
					lg="6"
					md="6"
					sm="12"
					class="d-flex flex-row-reverse"
				>
					<GroupedButton size="md" :buttonConfig="buttonConfig" />
				</b-col>
			</b-row>
			<div
				v-if="isFetching"
				class="
					w-100
					mt-3
					mb-2
					d-flex
					justify-content-center
					align-items-center
				"
			>
				<b-spinner variant="primary" class="spinner" />
			</div>

			<div v-if="!isFetching && summaryData.data">
				<b-row>
					<b-col cols="12" lg="4" md="4" sm="12">
						<div class="w-100 mt-1">
							<div
								class="
									d-flex
									justify-content-between
									align-content-start
								"
							>
								<div class="truncate">
									<h2
										class="
											font-weight-bolder
											text-secondary
											statistics
										"
										:class="statisticsClasses"
									>
										$
										{{
											numberFormatter(
												summaryData.data[0].totalAmount,
												1
											)
										}}
									</h2>
									<span
										class="sub-text-color statisticsTitle"
										:class="statisticTitleClasses"
										>Basic Plans</span
									>
								</div>
								<b-avatar class="earning-icon">
									<img
										src="@/assets/images/icons/basic.png"
									/>
								</b-avatar>
							</div>
						</div>
					</b-col>
					<b-col cols="12" lg="4" md="4" sm="12">
						<div class="w-100 mt-1 mb-1">
							<div
								class="
									d-flex
									justify-content-between
									align-content-start
								"
							>
								<div class="truncate">
									<h2
										class="
											font-weight-bolder
											text-secondary
											statistics
										"
										:class="statisticsClasses"
									>
										$
										{{
											numberFormatter(
												 0,
												1
											)
										}}
									</h2>
									<span
										class="sub-text-color statisticsTitle"
										:class="statisticTitleClasses"
										>Standard Plans</span
									>
								</div>
								<b-avatar class="location-earning-icon">
									<img
										src="@/assets/images/icons/standard.png"
									/>
								</b-avatar>
							</div>
						</div>
					</b-col>
					<b-col cols="12" lg="4" md="4" sm="12">
						<div class="w-100 mt-1 mb-1">
							<div
								class="
									d-flex
									justify-content-between
									align-content-start
								"
							>
								<div>
									<h2
										class="
											font-weight-bolder
											text-secondary
											statistics
										"
										:class="statisticsClasses"
									>
										$
										{{
											numberFormatter(
												0,
												1
											)
										}}
									</h2>
									<span
										class="sub-text-color statisticsTitle"
										:class="statisticTitleClasses"
										>Express Plans</span
									>
								</div>
								<b-avatar class="guide-trip-earning-icon">
									<img
										src="@/assets/images/icons/express.png"
									/>
								</b-avatar>
							</div>
						</div>
					</b-col>
				</b-row>
				<hr />

				<b-row
					class="labels-row"
					no-gutters
					style="margin-top: -13px !important"
				>
					<b-col cols="12" sm="12" md="6" lg="4">
						<div class="d-flex flex-column">
							<LabelText
								label="Basic Plan Users"
								:text="summaryData.data[0].totalUsers"
								textClasses="font-weight-bolder font-12"
								labelClasses="font-12"
							/>
							
						</div>
					</b-col>
					<b-col cols="12" sm="12" md="6" lg="4">
						<div class="d-flex flex-column">
							
							<LabelText
								label="Standard Plan Users"
								text="0"
								textClasses="font-weight-bolder font-12"
								labelClasses="font-12"
							/>
							
						</div>
					</b-col>
					<b-col cols="12" sm="12" md="6" lg="4">
						<div class="d-flex flex-column">
							
							<LabelText
								label="Express Plan Users"
								text="0"
								textClasses="font-weight-bolder font-12"
								labelClasses="font-12"
							/>
						</div>
					</b-col>
					
					
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BAvatar,
	BCardSubTitle,
	BCardTitle,
	BLink,
	BRow,
	BCol,
	BSpinner,
} from 'bootstrap-vue'

import GroupedButton from '../../components/GroupedButton.vue'
import StatisticsWidget from '../../components/StatisticsWidget.vue'
import LabelText from '../../components/LabelText.vue'
import { getSummary } from '../../api/api.service'
import { numberFormatter } from '../../utils/formatNumber'

export default {
	name: 'Summary',
	components: {
		BCard,
		BCardBody,
		BAvatar,
		BCardSubTitle,
		BCardTitle,
		BLink,
		BRow,
		BCol,
		BSpinner,
		GroupedButton,
		StatisticsWidget,
		LabelText,
	},
	props: {
		icon: {
			type: Function,
			required: true,
		},
		statistic: {
			type: [Number, String],
			required: true,
		},
		statisticTitle: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: 'primary',
		},
		iconColor: {
			type: String,
			default: 'primary',
		},
		width: {
			type: String,
			default: 'w-100',
		},
		statisticsData: Array,
	},
	beforeMount() {
		this.getSummaryHandler()
	},
	data() {
		return {
			numberFormatter,
			isFetching: false,
			buttonConfig: [
				{
					btnText: 'Total',
					trigger: () => this.getSummaryHandler(),
					activeClass: true,
				},
				{
					btnText: 'This Month',
					trigger: () => {
						this.buttonConfig[0].activeClass = false
						this.getSummaryHandler({ ThisMonth: 1 })
					},
					activeClass: false,
				},
			],
			summaryData: {
				data: null,
				filters: {
					FilterType: 4,
				},
			},
		}
	},
	methods: {
		async getSummaryHandler(param) {
			try {
				this.isFetching = true
				let response = await getSummary(param)
				this.summaryData.data = response.plans
				this.isFetching = false
			} catch (error) {
				this.isFetching = false
			} finally {
				this.isFetching = false
			}
		},
	},
}
</script>

<style scoped>
.sub-text-color {
	color: #F78F21;
}
.statistics {
	font-size: 18px;
}
.statisticsTitle {
	font-size: 12px;
}
.earning-icon {
	color: #FFF2E3;
	background-color: #FFF2E3;
}
.location-earning-icon {
	color: #FFF2E3;
	background-color: #FFF2E3;
}

.guide-trip-earning-icon {
	color: #FFF2E3;
	background-color: #FFF2E3;
}

.card-container {
	height: 200px !important;
}
.spinner {
	width: 4rem;
	height: 4rem;
}
/* .labels-row {
  max-height: 150px !important;
  border: 1px solid green !important;
} */
</style>
