var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "dadsadasd",
    ENVIRONMENT: "LOCAL",
    ENVIRONMENTS: {
      LOCAL: {
        API_URL: "https://api.ubnotify.app/v1",
      },
      DEVELOPMENT: {
        API_URL: "APP_API_URL",
      },
      STAGING: {
        API_URL: "APP_API_URL",
      },
      PRODUCTION: {
        API_URL: "APP_API_URL",
      },
    },
  };
  
  Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
  };
  
  export default Config;
  