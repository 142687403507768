<template>
	<div>
		<b-card class="w-100 card-container">
			<b-row>
				<b-col
					cols="12"
					lg="6"
					md="6"
					sm="12"
					class="d-flex align-items-center"
				>
					<b-card-title class="text-secondary font-weight-bolder">
						Subscription Plans
					</b-card-title>
				</b-col>
			</b-row>
			<div
				v-if="isFetching"
				class="
					w-100
					mt-3
					mb-2
					d-flex
					justify-content-center
					align-items-center
				"
			>
				<b-spinner variant="primary" class="spinner" />
			</div>

			<div v-if="!isFetching && summaryData.data">
				<b-row>
					<b-col cols="12" lg="12" md="12" sm="12">
						<div class="w-100 mt-1 plan-box">
                        <b-avatar class="earning-icon">
									<img
										src="@/assets/images/gallery/bulb.png"
									/>
								</b-avatar>
							<div
								class="
									d-flex
									justify-content-between
									align-content-start
								"
							>
                            <div class="price-info">
                            <div
										class="sub-text-color statisticsTitle"
										:class="statisticTitleClasses"
										>Basic</div>
								<div class="price">
									<h1
										class="
											font-weight-bolder
											text-secondary
											statistics
										"
										:class="statisticsClasses"
									>
										$
                                        <span class="price-label">
										{{
											numberFormatter(
												60,
												1
											)
                                        }} </span>
									</h1>
                                    <span class="unit-label"> / month</span>
								</div>
                                </div>
							</div>
                              <div class="plan-details">
                     <div class="plan-detail-sub"> <span class="plan-dot"></span>  <span class="plan-text">1 Twilio Number</span> </div>
                     <!-- <div class="plan-detail-sub"> <span class="plan-dot"></span>  <span class="plan-text">1 Twilio Number</span> </div> -->
                        </div>
						</div>
					</b-col>
									</b-row>
				

				
					
					
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BCardBody,
	BAvatar,
	BCardSubTitle,
	BCardTitle,
	BLink,
	BRow,
	BCol,
	BSpinner,
} from 'bootstrap-vue'

import GroupedButton from '../../components/GroupedButton.vue'
import StatisticsWidget from '../../components/StatisticsWidget.vue'
import LabelText from '../../components/LabelText.vue'
import { getSummary } from '../../api/api.service'
import { numberFormatter } from '../../utils/formatNumber'

export default {
	name: 'PlanSummary',
	components: {
		BCard,
		BCardBody,
		BAvatar,
		BCardSubTitle,
		BCardTitle,
		BLink,
		BRow,
		BCol,
		BSpinner,
		GroupedButton,
		StatisticsWidget,
		LabelText,
	},
	props: {
		icon: {
			type: Function,
			required: true,
		},
		statistic: {
			type: [Number, String],
			required: true,
		},
		statisticTitle: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: 'primary',
		},
		iconColor: {
			type: String,
			default: 'primary',
		},
		width: {
			type: String,
			default: 'w-100',
		},
		statisticsData: Array,
	},
	beforeMount() {
		this.getSummaryHandler()
	},
	data() {
		return {
			numberFormatter,
			isFetching: false,
			buttonConfig: [
				{
					btnText: 'Total',
					trigger: () => this.getSummaryHandler(),
					activeClass: true,
				},
				{
					btnText: 'This Month',
					trigger: () => {
						this.buttonConfig[0].activeClass = false
						this.getSummaryHandler({ ThisMonth: 1 })
					},
					activeClass: false,
				},
			],
			summaryData: {
				data: null,
				filters: {
					FilterType: 4,
				},
			},
		}
	},
	methods: {
		async getSummaryHandler(param) {
			try {
				this.isFetching = true
				let response = await getSummary(param)
				this.summaryData.data = response.plans
				this.isFetching = false
			} catch (error) {
				this.isFetching = false
			} finally {
				this.isFetching = false
			}
		},
	},
}
</script>

<style scoped>
.sub-text-color {
    color :black;
	font-weight:bold
}
.statistics {
	font-size: 12px;
}
.statisticsTitle {
	font-size: 18px;
}
.earning-icon {
	color: #338746;
	background-color: #FFF2E3;
    height: 100%;
    width: 14%
}
.location-earning-icon {
	color: #713f3f;
	background-color: #f067671f;
}
.plan-box{
    border: 1px solid #F78F21;
    border-radius : 6px;
    padding : 10px;
    display : flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.plan-dot{
    height: 9px;
  width: 9px;
  background-color: #F78F21;
  border-radius: 50%;
  display: inline-block;
  margin-right:5px;
  margin-top:5px;
}
.guide-trip-earning-icon {
	color: #226694;
	background-color: #3790d51f;
}

.card-container {
	height: 200px !important;
}
.spinner {
	width: 4rem;
	height: 4rem;
}
.plan-details{
    display:flex;
    flex-direction:column;
}
.plan-detail-sub{
    display:flex;
    flex-direction:row;
     justify-content: space-between
}
.plan-text{
}
.price-info{
    display:flex;
    flex-direction:column;
    justify-content: space-between
}
.price{
    display:flex;
    flex-direction:row;
    justify-content: space-between

}
.price-label{
    color:#F78F21;
    font-size:30px;
}
.unit-label{
    margin-top:20px;
}
/* .labels-row {
  max-height: 150px !important;
  border: 1px solid green !important;
} */
</style>
