<template #cell(user)="data">
	<b-media
		vertical-align="center"
		class="d-flex align-items-center"
		:class="containerClasses"
	>
		<!-- <template #aside>
			<b-avatar
				:size="iconSize"
				:src="avatarImage"
				class="avatar"
				:text="!avatarImage && fullName && fullName[0]"
			/>
		</template> -->
		<h5
			class="
				font-weight-bolder
				d-block
				text-nowrap
				mb-0
				text-dark
				name-text
			"
			:class="nameClasses"
		>
			{{ fullName }}
		</h5>
		<small class="text-muted">{{ subText }}</small>
	</b-media>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
} from 'bootstrap-vue'

export default {
	name: 'UserInfo',
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
	},
	props: {
		fullName: {
			type: String,
		},
		subText: {
			type: String,
		},
		iconSize: {
			type: String,
			default: 'lg',
		},
		nameClasses: String,
		containerClasses: String,
		avatarImage: {
			type: String,
		},
	},
}
</script>

<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.avatar {
	border: 1px solid white !important;
}
.text-dark {
	color: black !important;
	font-size: 1rem !important;
}
.name-text {
	max-width: 170px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.marginT {
	margin-top: 5px;
}
.marginT {
	margin-top: 5px;
}
</style>
