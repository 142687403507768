import { $themeColors } from "@themeConfig";
import { chartColors } from "./chartColors";

export const transactionChartConfig = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    backgroundColor: false,
    hover: {
      mode: "label",
    },
    layout: {
      padding: {
        top: -15,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          type: "time",
          scaleLabel: {
            display: true,
            labelString: "Date",
          },
          time: {
            parser: "YYYY-MM-DD",
            tooltipFormat: "ll",
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              day: "YYYY-MM-DD",
            },
          },
          gridLines: {
            display: true,
            color: chartColors.grid_line_color,
            zeroLineColor: chartColors.grid_line_color,
          },
          scaleLabel: {
            display: true,
            // labelString: "Days",
          },
          ticks: {
            display: false,
            fontColor: chartColors.labelColor,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Amount",
          },
          ticks: {
            display: false,
            stepSize: 500,
            min: 0,
            max: 5000,
            fontColor: chartColors.labelColor,
          },
          gridLines: {
            display: true,
            color: chartColors.grid_line_color,
            zeroLineColor: chartColors.grid_line_color,
          },
        },
      ],
    },
    legend: {
      position: "top",
      align: "start",
      labels: {
        usePointStyle: true,
        padding: 25,
        boxWidth: 9,
      },
    },
  },
  data: {
    labels: [],
    datasets: [
      {
        data: [],

        label: "Total Earning",
        borderColor: chartColors.lineChartDanger,
        lineTension: 0.5,
        pointStyle: "circle",
        backgroundColor: chartColors.lineChartDanger,
        fill: false,
        pointRadius: 5,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: "transparent",
        pointHoverBorderColor: $themeColors.white,
        pointHoverBackgroundColor: chartColors.lineChartDanger,
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
        pointShadowBlur: 5,
        pointShadowColor: chartColors.tooltipShadow,
      },
      // {
      //   data: [],

      //   label: "Fishing Spots",
      //   borderColor: chartColors.lineChartPrimary,
      //   lineTension: 0.5,
      //   pointStyle: "circle",
      //   backgroundColor: chartColors.lineChartPrimary,
      //   fill: false,
      //   pointRadius: 5,
      //   pointHoverRadius: 5,
      //   pointHoverBorderWidth: 5,
      //   pointBorderColor: "transparent",
      //   pointHoverBorderColor: $themeColors.white,
      //   pointHoverBackgroundColor: chartColors.lineChartPrimary,
      //   pointShadowOffsetX: 1,
      //   pointShadowOffsetY: 1,
      //   pointShadowBlur: 5,
      //   pointShadowColor: chartColors.tooltipShadow,
      // },
    ],
  },
};
