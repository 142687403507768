<template>
  <div>
    <b-card body-class="card-body-style">
      <!-- <SearchHeader
        :showStatusFilter="false"
        :showSearchField="false"
        perPageSelectorText="Select Year"
        :perPageOptions="perPageOptions"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimitChange"
      /> -->
      <!-- <datepicker
        @selected="onChange"
        :minimumView="'year'"
        :maximumView="'year'"
        :format="customFormatter"
        placeholder="Select Year"
        input-class="datepicker"
        :disabled-dates="disabledDates"
        clear-button-icon
        calendar-class="open-left"
      /> -->

      <DataTable
        :perPageLimit="pageLimit"
        entriesName="Reports"
        :isLoading="isFetching"
        :config="config"
        :data="data"
        :perPage="data.length"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageClick"
        :totalRecords="total"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(TotalEarning)="data">
          <span class="font-weight-bolder"> {{ data.value }} </span>
        </template>
      </DataTable>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import moment from "moment";

import DataTable from "@/components/data-table/DataTable.vue";
import UserInfo from "@/components/UserInfo.vue";
import SearchHeader from "@/components/SearchHeader.vue";
import { dateTimeFormat, convertDateTime } from "@/utils/dateFormatUtils";
import { getMediaPath, getStatusOptions } from "@/utils/commonUtils";
import { useLocalPagination } from "@/hooks/useLocalPagination";
import { getOrdersLisiting } from "@/api/api.service";

const config = [
  {
    label: "MONTHS",
    key: "Month",
  },
  {
    label: "NEW SUBSCRIPTIONS",
    key: "NewSubscriptions",
    sortable: true,
  },
  {
    label: "RECURRING SUBSCRIPTIONS",
    key: "RecurringSubscriptions",
    sortable: true,
  },
  {
    label: "CANCELLED SUBSCRIPTIONS",
    key: "CancelledSubscriptions",
    sortable: true,
  },
  {
    label: "TOTAL EARNING",
    key: "TotalEarning",
    sortable: true,
  },
].map((item) => {
  return { ...item, tdClass: "table-row-medium-text" };
});

export default {
  name: "SubscribtionReport",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    BFormInput,
    Datepicker,
    DataTable,
    UserInfo,
    SearchHeader,
  },

  setup(props, context) {
    const isLoading = ref(false);
    const isSuccess = ref(false);
    const isError = ref(false);
    const perPageOptions = ref([2020, 2019, 2018, 2017, 2016]);
    const customFormatter = (date) => {
      return moment(date).format("YYYY");
    };
    const disabledDates = () => {
      return {
        from: new Date(),
      };
    };
    const data = ref([
      {
        Month: "January",
        NewSubscriptions: 10,
        RecurringSubscriptions: 200,
        CancelledSubscriptions: 30,
        TotalEarning: "$300",
      },
      {
        Month: "February",
        NewSubscriptions: 14,
        RecurringSubscriptions: 210,
        CancelledSubscriptions: 40,
        TotalEarning: "$400",
      },
      {
        Month: "March",
        NewSubscriptions: 20,
        RecurringSubscriptions: 220,
        CancelledSubscriptions: 50,
        TotalEarning: "$500",
      },
      {
        Month: "April",
        NewSubscriptions: 30,
        RecurringSubscriptions: 230,
        CancelledSubscriptions: 60,
        TotalEarning: "$600",
      },
      {
        Month: "May",
        NewSubscriptions: 50,
        RecurringSubscriptions: 240,
        CancelledSubscriptions: 70,
        TotalEarning: "$700",
      },
    ]);

    const {
      // :TODO: data will destructure when integrate api
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = useLocalPagination(
      getOrdersLisiting,
      () => ({}),
      [],
      "Q",
      "key",
      "total"
    );
    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };
    return {
      perPageOptions,
      config,
      data,
      isLoading,
      isSuccess,
      config,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      isError,
      dateTimeFormat,
      handlePageChange,
      handlePageLimitChange,
      convertDateTime,
      getMediaPath,
      getStatusOptions,
      handleSearchChange,
      handleSortChange,
      customFormatter,
      disabledDates,
    };
  },
};
</script>

<style scoped>
.card-body-style {
  padding: 0 !important;
}

::v-deep .table-row-medium-text {
  font-weight: 500;
  color: black;
}

::v-deep .month-selector {
  width: 190px !important;
}

::v-deep .search-field {
  width: 170px !important;
}

::v-deep .search-text {
  margin-left: 2px !important;
}

.amount-text {
  color: #003071;
  margin-left: 10px !important;
}

.amount-description {
  margin-left: 10px !important;
  margin-right: 4px !important;
}

.earning-card {
  padding: 6px 6px !important;
  border: 1px solid #F78F21 !important;
  border-radius: 0.5rem !important;
  background-color: #FFF2E3;
}
</style>

<style scoped>
.open-left {
  margin-left: 10px !important;
}
</style>

