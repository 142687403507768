import { injectBaseConstantMethods } from './BaseConstants'

const TournamentStatus = {
    NotStarted: 1,
    OnGoing: 2,
    WaitingForResult: 3,
    Completed: 4,
}

const displayTextKeys = {
    [TournamentStatus.Completed]: 'Completed',
    [TournamentStatus.NotStarted]: 'Not Started',
    [TournamentStatus.OnGoing]: 'On Going',
    [TournamentStatus.WaitingForResult]: 'Waiting For Result',
}

const labelClass = {
    [TournamentStatus.Completed]: 'tournament-completed',
    [TournamentStatus.NotStarted]: 'tournament-not-started',
    [TournamentStatus.OnGoing]: 'tournament-on-going',
    [TournamentStatus.WaitingForResult]: 'tournament-waiting-for-result',
}

export default injectBaseConstantMethods(
    TournamentStatus,
    displayTextKeys,
    labelClass
)
