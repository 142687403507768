import { injectBaseConstantMethods } from "./BaseConstants";

export const StatusCodes = {
    ACTIVE: "ACTIVE",
    INACITVE: "INACTIVE",
};

const displayTextKeys = {
    [StatusCodes.ACTIVE]: "Active",
    [StatusCodes.INACITVE]: "Suspended",
};

const labelClass = {
    [StatusCodes.ACTIVE]: "light-success",
    [StatusCodes.INACITVE]: "light-danger",
};

export default injectBaseConstantMethods(
    StatusCodes,
    displayTextKeys,
    labelClass
);
