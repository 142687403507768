import { injectBaseConstantMethods } from "./BaseConstants";

export const Plan = {
    BASIC: 1,
};

const displayTextKeys = {
    [Plan.BASIC]: "Basic",
};

const labelClass = {
    [Plan.BASIC]: "light-success",

};

export default injectBaseConstantMethods(
    Plan,
    displayTextKeys,
    labelClass
);
