<template>
  <div class="mt-2">
    <b-card body-class="card-body-style p-0">
      <div class="p-1">
        <SearchHeader
          :showSearchField="false"
          :showStatusFilter="false"
          :pageLimitValue="pageLimit"
          :onPageLimitChange="handlePageLimitChange"
          classes="margin-bottom-only"
        >
          <template #left-header-content>
            <span class="search-label mr-1">Search</span>
            <b-form-input
              @input="handleSearchChange"
              :value="searchText"
              class="d-inline-block w-25"
            />
          </template>
          <template #right-header-content>
            <Button
              buttonVariant="outline-primary"
              :buttonText="`+ Add New ${FishingGuideMetaType.getDisplayTextKey(
                type.Type
              )}`"
              v-b-modal="'add-specie-modal'"
              :clickHandler="() => showModal('form-modal')"
            />
          </template>
        </SearchHeader>
      </div>
      <data-table
        :perPageLimit="pageLimit"
        entriesName="Policies"
        :config="config"
        :data="data || []"
        tableClasses="br-6"
        :isLoading="isFetching"
        :perPage="data.length"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageChange"
        :totalRecords="total"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <!-- Id -->
        <template #cell(Id)="data">
          <span class="font-weight-bolder"> {{ data.item.Id }} </span>
        </template>
        <!-- Name -->
        <template #cell(Name)="data">
          <span class="text-nowrap font-weight-bolder">
            {{ data.item.Name }}
          </span>
        </template>

        <!-- Actions -->
        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-b-tooltip.hover.top="'Edit'"
              icon="EditIcon"
              size="16"
              class="mr-3 detail-icon cursor-pointer"
              v-on:click="() => editHandler(data.item.Id)"
            />

            <feather-icon
              v-b-tooltip.hover.top="'Delete'"
              :icon="`Trash2Icon`"
              class="delete-icon cursor-pointer"
              size="16"
              v-b-modal="'delete-modal'"
              @click="setId(data.item.Id)"
            />
          </div>
        </template>
      </data-table>
    </b-card>
    <BasicModal
      id="form-modal"
      :onClose="handleModalClose"
      :modalTitle="
        currentPolicy
          ? `Edit ${FishingGuideMetaType.getDisplayTextKey(type.Type)}`
          : `Add New ${FishingGuideMetaType.getDisplayTextKey(type.Type)}`
      "
    >
      <template #showForm>
        <!-- If User Clicks on Add / Edit Button -->

        <div>
          <validation-observer ref="validationRules">
            <b-form @submit.prevent="handleSubmit">
              <b-form-group
                label-cols="4"
                :label="`${FishingGuideMetaType.getDisplayTextKey(
                  type.Type
                )} Name: `"
                :label-for="`${FishingGuideMetaType.getDisplayTextKey(
                  type.Type
                )}`"
                label-size="md"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:1|max:100"
                  :name="`${FishingGuideMetaType.getDisplayTextKey(type.Type)}`"
                >
                  <Input
                    :placeholder="` Type ${FishingGuideMetaType.getDisplayTextKey(
                      type.Type
                    )} Name`"
                    :id="`${FishingGuideMetaType.getDisplayTextKey(type.Type)}`"
                    size="md"
                    classes="w-100"
                    v-model="policy"
                    :errors="errors"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Show when edit  -->
              <div class="d-flex justify-content-end">
                <div class="w-50">
                  <Button
                    buttonVariant="primary"
                    :buttonText="
                      policy
                        ? 'Save Changes'
                        : `Add ${FishingGuideMetaType.getDisplayTextKey(
                            type.Type
                          )}`
                    "
                    classes="w-100"
                    type="submit"
                    :isLoading="isLoading"
                  />
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </BasicModal>
    <ConfirmModal
      id="delete-modal"
      :onYesPress="handleDelete"
      :isLoading="isLoading"
      :isSuccess="isSuccess"
      :onOkayPress="handleOkayPress"
      :messageText="`Are you sure you want to delete this ${FishingGuideMetaType.getDisplayTextKey(
        type.Type
      )} ?`"
      :successText="`${FishingGuideMetaType.getDisplayTextKey(
        type.Type
      )} deleted successfully !`"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
  BFormGroup,
  BFormFile,
  VBTooltip,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import { ref } from "@vue/composition-api";

import DataTable from "../../../components/data-table/DataTable.vue";
import SearchHeader from "../../../components/SearchHeader.vue";
import Button from "../../../components/Button.vue";
import BasicModal from "../../../components/BasicModal.vue";
import ConfirmModal from "../../../components/ConfirmModal.vue";
import Input from "../../../components/Input.vue";
import Select from "../../../components/Select.vue";
import FileInput from "../../../components/FileInput.vue";
import { useLocalPagination } from "../../../hooks/useLocalPagination";
import { showModal, hideModal, emptyFields } from "../../../utils/commonUtils";
import showToast from "../../../hooks/useToast";
import {
  getFishingGuideMeta,
  addFishingGuideMeta,
  updateFishingGuideMeta,
  deleteFishingGuideMeta,
} from "../../../api/api.service";
import { FishingGuideMetaType, SortOrder } from "../../../constants";

let config = [
  {
    label: "No",
    key: "Id",
  },
  {
    label: "Name",
    key: "Name",
  },

  {
    label: "ACTIONS",
    key: "Actions",
  },
].map((item) => {
  return { ...item, tdClass: "table-row", thClass: "table-header" };
});

export default {
  name: "Amenities",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,

    BasicModal,
    Input,
    Select,
    FileInput,
    VBTooltip,
    SearchHeader,
    ConfirmModal,
    Button,
    DataTable,
  },

  setup(props, context) {
    let type = {
      Type: 4,
    };
    const policy = ref("");
    const currentPolicy = ref(null);
    const isLoading = ref(false);
    const isSuccess = ref(false);
    const isError = ref(false);
    const policyId = ref(null);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
      request,
    } = useLocalPagination(
      getFishingGuideMeta,
      () => ({ Direction: SortOrder.DESC, Column: "CreatedAt", ...type }),
      [],
      "Name",
      "GuideTripMetaData",
      "TotalGuideTripMetaData"
    );

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    function handleModalClose() {
      setTimeout(() => {
        emptyFields([policy, currentPolicy, isLoading, isSuccess]);
      }, 1000);
    }

    async function handleSubmit() {
      let canSubmit = await this.$refs.validationRules.validate();
      if (!canSubmit) {
        return;
      }

      if (currentPolicy.value) {
        handleUpdate();
      } else {
        handleCreate();
      }
    }

    const handleCreate = async () => {
      if (!policy.value) {
        return;
      }

      try {
        isError.value && !isError.value;
        isLoading.value = true;

        let bodyData = {
          Name: policy.value,
          Type: type.Type,
        };
        let uploaded = await addFishingGuideMeta(type.Type, bodyData);

        isSuccess.value = true;
        isLoading.value = false;

        hideModal("form-modal", context);

        if (page.value !== 1) {
          page.value = 1;
        } else request();

        showToast(context.root, {
          Title: "Added Successfully",
          Icon: "CheckCircleIcon",
        });
      } catch (err) {
        isError.value = true;
        isLoading.value = false;
        showToast(context.root, {
          Title: "Error",
          Icon: "XCircleIcon",
          Text: err.Message,
          Variant: "danger",
        });
        return;
      } finally {
        isLoading.value = false;
        isSuccess.value = false;
      }
    };

    const handleUpdate = async () => {
      if (!currentPolicy.value) {
        return;
      }

      try {
        isError.value && !isError.value;
        isLoading.value = true;

        let bodyData = {
          Name: policy.value,
          Type: type.Type,
        };
        let updated = await updateFishingGuideMeta(
          currentPolicy.value.Id,
          bodyData
        );
        isSuccess.value = true;
        isLoading.value = false;
        if (page.value !== 1) {
          page.value = 1;
        } else request();
        hideModal("form-modal", context);
        showToast(context.root, {
          Title: "Updated Successfully",
          Icon: "CheckCircleIcon",
        });
      } catch (err) {
        isError.value = true;
        showToast(context.root, {
          Title: "Error",
          Icon: "XCircleIcon",
          Text: err.Message,
          Variant: "danger",
        });
        return;
      } finally {
        isLoading.value = false;
        isSuccess.value = false;
      }
    };

    const handleDelete = async () => {
      let id = policyId.value;

      try {
        isLoading.value = true;
        let deleted = await deleteFishingGuideMeta(id);
        data.value = data.value.filter((item) => item.Id != id);
        --total.value;
        isSuccess.value = true;
      } catch {
        isLoading.value = false;
        isSuccess.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    function editHandler(id) {
      if (id) {
        let Listing = data.value.find((item) => item.Id == id);
        currentPolicy.value = Listing;
        policy.value = Listing.Name;
        showModal("form-modal", context);
      }
    }

    const setId = (id) => {
      policyId.value = id;
    };
    const handleOkayPress = () => {
      isSuccess.value = false;
    };

    return {
      policy,
      currentPolicy,
      isLoading,
      isSuccess,
      config,
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      isError,
      type,
      handlePageChange,
      handlePageLimitChange,
      handleSearchChange,
      handleSortChange,
      showModal,
      handleSubmit,
      editHandler,
      handleModalClose,
      handleDelete,
      setId,
      handleOkayPress,
      hideModal,
      FishingGuideMetaType,
      // validations
      required,
      min,
      max,
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.delete-icon {
  color: red;
}

.detail-icon {
  color: #F78F21;
}
::v-deep .table-row {
  font-weight: bold;
}
/* ::v-deep .table-header {
  width: 30%;
} */
</style>
