import { injectBaseConstantMethods } from './BaseConstants'

export const ReportEntityType = {
    User: 1,
    FishSpecie: 2,
    FishSpot: 3,
    Gear: 4,
    Location: 5,
    GuideTrip: 6,
    CatchLog: 7,
    Booking: 8,
    Voucher: 9,
    UserStory: 12,
    CommunityPost: 13,
    ConfigSetting: 14,
    UserStoryTag: 15,
    Group: 16,
    GroupPost: 17,
    GroupPostComment: 18,
}
const displayTextKeys = {
    [ReportEntityType.User]: 'User',
    [ReportEntityType.FishSpecie]: 'Fish Species',
    [ReportEntityType.FishSpot]: 'Fish Spot',
    [ReportEntityType.Gear]: 'Gear',
    [ReportEntityType.Location]: 'Location',
    [ReportEntityType.GuideTrip]: 'Trip',
    [ReportEntityType.CatchLog]: 'Catchlog',
    [ReportEntityType.Booking]: 'Booking',
    [ReportEntityType.Voucher]: 'Voucher',
    [ReportEntityType.UserStory]: 'User Story',
    [ReportEntityType.CommunityPost]: 'Community Post',
    [ReportEntityType.ConfigSetting]: 'Config Setting',
    [ReportEntityType.UserStoryTag]: 'User Story Comment',
    [ReportEntityType.Group]: 'Group',
    [ReportEntityType.GroupPost]: 'Group Post',
    [ReportEntityType.GroupPostComment]: 'Group Post Comment',
}

const labelClass = {
    [ReportEntityType]: '',
}

export default injectBaseConstantMethods(
    ReportEntityType,
    displayTextKeys,
    labelClass
)

/*

Rightnow these are available in api
----------------------------------- 
Gear
Group
GroupPost
GroupPostComment
UserStory
UserStoryTag
GuideTrip
Location

*/
