var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"body-container"},[(_vm.isLoading)?_c('div',{staticClass:"loader-container"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary"}})],1):_vm._e(),(!_vm.isLoading && !_vm.groupPostData)?_c('div',{staticClass:"loader-container"},[_c('p',{staticClass:"msg"},[_vm._v("Group post details not found !")])]):_vm._e(),(_vm.groupPostData && !_vm.isLoading)?_c('b-container',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"12"}},[_c('LabelText',{attrs:{"label":"Posted By","text":(_vm.groupPostData &&
								_vm.groupPostData.User.FullName) ||
							'-',"showRightSection":"","textClasses":"font-weight-bolder mt-0"}},[_c('b-avatar',{staticClass:"avatar",attrs:{"size":"md","src":_vm.getMediaPath(_vm.groupPostData.User),"text":_vm.groupPostData.User &&
								_vm.groupPostData.User.FullName[0]}})],1),_c('div',{staticClass:"neg-margin mt-3"},[_c('LabelText',{attrs:{"label":"Date","text":_vm.convertDateTime({
									date: _vm.groupPostData.CreatedAt,
									customFormat:
										_vm.dateTimeFormat.appDateFormat,
									dateOnly: true,
								}),"showRightSection":"","textClasses":"font-weight-bolder"}})],1),_c('div',{staticClass:"neg-margin"})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"12"}},[_c('LabelText',{attrs:{"label":"Group Name","text":_vm.groupPostData.Group.Name || '-',"textClasses":"font-weight-bolder"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"4","sm":"12"}},[_c('Button',{attrs:{"buttonText":"Delete Post","buttonVariant":"danger","classes":"mb-1 w-50","clickHandler":_vm.handleDeletePress}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('h6',{staticClass:"text-secondary mb-3 font-weight-bolder"},[_vm._v(" Post Text ")]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.groupPostData.Description || 'No content to show...')+" ")])]),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('h6',{staticClass:"text-secondary mb-3 font-weight-bolder"},[_vm._v(" Post Images ")]),(Boolean(_vm.groupPostData.Medias.length))?_c('div',[_c('ImageGallery',{attrs:{"galleryConfig":_vm.groupPostData.Medias}})],1):_c('div',{staticStyle:{"color":"black"}},[_vm._v("No Images")])])],1)],1)]):_vm._e(),_c('ConfirmModal',{attrs:{"id":"post-modal","isLoading":_vm.isProcessing,"isSuccess":_vm.deleteSuccess,"successText":"Post Deleted Successfully !","onOkayPress":_vm.handleOkayPress,"onYesPress":_vm.handleYesPress,"messageText":"Are you sure you want to delete this post?"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }