<template>
	<b-container>
		<!-- --STATISTICS SECTION -->

		<b-row>
			<b-col col lg="4" md="6" sm="12">
				<StatisticsWidget
					:fetching="analyticsData[analyticsConst.Users].isFetching"
					:statistic="`${
						analyticsData[analyticsConst.Users].count
					}`"
					statisticTitle="Total Clients"
					width="w-100"
					iconName="UserIcon"
					iconColor="users-icon"
					color="success"
					:onClick="getUserHandler"
				/>
			</b-col>
			<b-col col lg="4" md="6" sm="12">
				<StatisticsWidget
					:hasCustomButtons="true"
					:fetching="analyticsData[analyticsConst.RevenueThisMonth].isFetching"
					:statistic="`${analyticsData[analyticsConst.RevenueThisMonth].count}`"
					statisticTitle="Revenue This Month"
					width="w-100"
					iconName="DollarSignIcon"
					iconColor="revenue-icon"
					color="custom-color"
				>
				</StatisticsWidget>
			</b-col>
			<!-- <b-col col lg="3" md="6" sm="12">
				<StatisticsWidget
					:fetching="
						analyticsData[analyticsConst.SubscribedUsers].isFetching
					"
					:statistic="`${
						analyticsData[analyticsConst.SubscribedUsers].count
					}`"
					statisticTitle="Subscribed Users"
					width="w-100"
					iconColor="subscribed-users-icon"
					:onClick="getSubscribedUsersHandler"
				>
					<template v-slot:customIcon>
						<img src="@/assets/images/icons/crown-icon.svg" />
					</template>
				</StatisticsWidget>
			</b-col> -->
			<b-col col lg="4" md="6" sm="12">
				<StatisticsWidget
					:fetching="analyticsData[analyticsConst.Revenue].isFetching"
					:statistic="`${analyticsData[analyticsConst.Revenue].count}`"
					statisticTitle="Total Revenue"
					width="w-100"
					iconName="DollarSignIcon"
					iconColor="revenue-icon"
					>
					</StatisticsWidget
			></b-col>
		</b-row>

		<b-row>
			<b-col col xl="6" lg="12" md="12" sm="12"> <Summary /></b-col>
			<b-col col xl="6" lg="12" md="12" sm="12">
				<PlanSummary/>
			</b-col>
		</b-row>

		<b-row>
			<b-col col lg="6" md="6" sm="12">
				<ChartBox
					title="Earning Graph"
					:graphData="transactionChartConfigLocal"
					:onChange="setTransactionYear"
			/></b-col>
			<!-- <b-col col lg="6" md="6" sm="12">
				<ChartBox
					title="Product Listing"
					:graphData="productListingChartConfigLocal"
					:onChange="setProductYear"
					:onWeekChange="setProductWeek"
					:weekValue="productWeek"
			/></b-col> -->
		</b-row>
	</b-container>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'

import StatisticsWidget from '../../components/StatisticsWidget.vue'
import ChartBox from '../../components/chart/LineChartBox.vue'
import { transactionChartConfig } from '../../components/chart/config/transactionsChartConfig'
import { productListingChartConfig } from '../../components/chart/config/productListingChartConfig'
import GuideVerificationRequest from './HomeGuideVerificationRequest.vue'
import Summary from './HomeSummary.vue'
import PlanSummary from './PlanSummary.vue';
import {
	getRevenue,
	getTotalClients,
	getSubscribedUsers,
	getTotalGuides,
	getTransactionChartData,
	getProductsChartData,
} from '../../api/api.service'
import { analyticsConst, ListingVerfCode } from '../../constants'
import GroupedButton from '@/components/GroupedButton.vue'

export default {
	name: 'Home',
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BLink,
		GroupedButton,
		StatisticsWidget,
		ChartBox,
		GuideVerificationRequest,
		Summary,
		PlanSummary
	},
	beforeMount() {
		// this.getProductsChartDataHandler({
		// 	YearAndWeek: `${moment().format('YYYY-WW')}`,
		// })
		this.getTransactionChartDataHandler({
			month: `${moment().format('MM-YYYY')}`,
		})
		this.getUseresHandler();
		this.getRevenueHandler(
		)
		this.getThisMonthRevenueHandler(
			this.analyticsData[analyticsConst.RevenueThisMonth].filters
		)
	},

	methods: {
		async getRevenueHandler(param) {
			const loader = this.analyticsData[analyticsConst.Revenue]
			try {
				loader.isFetching = true
				let response = await getRevenue(param)
				this.analyticsData[analyticsConst.Revenue].count =
					response.total
				loader.isFetching = false
			} catch (error) {
				loader.isFetching = false
			} finally {
				loader.isFetching = false
			}
		},
		async getThisMonthRevenueHandler(param) {
			const loader = this.analyticsData[analyticsConst.RevenueThisMonth]
			try {
				loader.isFetching = true
				let response = await getRevenue(param)
				this.analyticsData[analyticsConst.RevenueThisMonth].count =
					response.total
				loader.isFetching = false
			} catch (error) {
				loader.isFetching = false
			} finally {
				loader.isFetching = false
			}
		},
		async getUseresHandler(param = {}, id = 1) {
			const loader = this.analyticsData[analyticsConst.Users]
			try {
				loader.isFetching = true
				let response = await getTotalClients(param)
				this.analyticsData[analyticsConst.Users].count =
					response.Total
				loader.isFetching = false
			} catch (error) {
				loader.isFetching = false
			} finally {
				loader.isFetching = false
			}
		},

		async getTransactionChartDataHandler(param) {
			try {
				let response = await getTransactionChartData(param)
				let updatedData = {
					...this.transactionChartConfigLocal,
					data: {
						...this.transactionChartConfigLocal.data,
						labels: Object.keys(response),
						datasets:
							this.transactionChartConfigLocal.data.datasets.map(
								(item, index) => {
									let result = { ...item }
									if (index === 0) {
										result.data = Object.keys(
											response
										).map(
											(item) =>
												response[item]
										)
									}
									return result
								}
							),
					},
				}
				this.transactionChartConfigLocal = updatedData
			} catch (error) {
				console.log("err",error)
			}
		},

		async getProductsChartDataHandler(param) {
			try {
				let response = await getProductsChartData(param)
				let updatedData = {
					...this.productListingChartConfigLocal,
					data: {
						...this.productListingChartConfigLocal.data,
						labels: Object.keys(response.TotalProductListing),
						datasets:
							this.productListingChartConfigLocal.data.datasets.map(
								(item, index) => {
									let result = { ...item }
									if (index === 0) {
										result.data = Object.keys(
											response.TotalProductListing
										).map(
											(item) =>
												response.TotalProductListing[
													item
												].GuideTrips
										)
									} else if (index === 1) {
										result.data = Object.keys(
											response.TotalProductListing
										).map(
											(item) =>
												response.TotalProductListing[
													item
												].FishingSpots
										)
									} else {
										result.data = Object.keys(
											response.TotalProductListing
										).map(
											(item) =>
												response.TotalProductListing[
													item
												].Gear
										)
									}
									return result
								}
							),
					},
				}
				this.productListingChartConfigLocal = updatedData
			} catch (error) {}
		},

		setTransactionYear(value) {
			this.transactionYear = moment(value).format('MM-YYYY')
		},

	},
	watch: {
		transactionYear: function (val) {
			if (!this.transactionYear) {
				return
			}

			let param = this.transactionYear
			this.getTransactionChartDataHandler({ month: param })
		},

	},
	data() {
		return {
			transactionChartConfigLocal: transactionChartConfig,
			productListingChartConfigLocal: productListingChartConfig,
			analyticsConst,

			transactionYear: null,
			transactionMonth: null,
			productYear: null,
			productWeek: null,

			analyticsData: {
				[analyticsConst.Revenue]: {
					count: 0,
					isFetching: false,
					filters: {
						FilterType: 1,
					},
				},
				[analyticsConst.Users]: {
					count: 0,
					isFetching: false,
					filters: {
						FilterType: 1,
					},
				},
				[analyticsConst.RevenueThisMonth]: {
					count: 0,
					isFetching: false,
					filters: {
						ThisMonth: 1,
					},
				},
			},
			userButtonsConfig: [
				{
					id: 1,
					btnText: 'All',
					trigger: () => this.getUseresHandler({}, 1),
					activeClass: true,
				},
				{
					id: 2,
					btnText: 'Active',
					trigger: () =>
						this.getUseresHandler(
							{
								Status: 1,
							},
							2
						),
					activeClass: false,
				},
				{
					id: 3,
					btnText: 'Inactive',
					trigger: () =>
						this.getUseresHandler(
							{
								Status: 2,
							},
							3
						),
					activeClass: false,
				},
			],
		}
	},
}
</script>

<style scoped>
.row-height {
	height: 30vh;
}
</style>

