import Vue from 'vue'
import VueRouter from 'vue-router'

//Home Views
import Home from '../views/home/Home.vue'

//Auth Views
import Auth from '../views/auth/Auth.vue'
import ForgetPassword from '../views/auth/login/AuthForgetPassword.vue'
import ResetPassword from '../views/auth/login/AuthResetPassword.vue'
import AuthLogin from '../views/auth/login/AuthLogin.vue'

// User Management Views
import UserDetails from '../views/user-management/user-details/UserDetails.vue'
import UserManagement from '../views/user-management/UserListing.vue'
import Earning from '../views/user-management/Earning.vue'
import Reviews from '../views/user-management/Reviews.vue'

// Moderation Views
import Moderation from '../views/moderation/Moderation.vue'
import ModerationUserStories from '../views/moderation/Moderation-UserStories.vue'
import ModerationMarketplace from '../views/moderation/Moderation-Marketplace.vue'
import ModerationGroups from '../views/moderation/Moderation-Groups.vue'

// import ModerationListing from '../views/moderation/ModerationListing.vue'
import ModerationDetail from '../views/moderation/DetailsView.vue'

// Fish Guide Views
import FishGuideListing from '../views/fishing-guides/FishGuideListing.vue'
import GuideDetails from '../views/fishing-guides/guide-details/GuideDetails.vue'
import TripDetails from '../views/fishing-guides/TripDetails.vue'
import BookingDetails from '../views/fishing-guides/guide-details/BookingDetails.vue'

// FishGear View
import FishGearListing from '../views/fishing-gear/FishGearListing.vue'
import GearDetails from '../views/fishing-gear/GearDetails.vue'

// User Story Detial
import UserStoryDetail from '../views/user-story-detail/UserStoryDetail.vue'

// FishSpot View
import FishSpotListing from '../views/fishing-spots/FishSpotListing.vue'
import FishingSpotDetails from '../views/fishing-spots/FishingSpotDetails.vue'

// Report View
import TransactionReport from '../views/report/TransactionReport.vue'
import SubscriptionReport from '../views/report/SubscriptionReport.vue'
import SOSReport from '../views/report/SOSReport.vue'

//Rating And Review Views
import RatingReviews from '../views/rating-reviews/RatingReviews.vue'
import SellerReview from '../views/rating-reviews/Review.vue'

// Monthly Submissions
// import MonthlySubmissions from '../views/monthly-submissions/MonthlySubmissions.vue'
// import AcceptedSubmissions from '../views/monthly-submissions/MonthlySubmissions-Accepted-Submissions.vue'
// import PreviousSubmissions from '../views/monthly-submissions/MonthlySubmissions-Previous-Submissions.vue'
// import SubmissionRequests from '../views/monthly-submissions/MonthlySubmissions-Submission-Requests.vue'

// User Stories View
import UserStories from '../views/user-stories/UserStories.vue'

// Settings Views
import ConfigurationSettings from '../views/settings/ConfigurationSettings.vue'

// --Fishing Gears Views
import FishingGears from '../views/settings/Fishing-Gears/FishingGears.vue'
import FishingGearsList from '../views/settings/Fishing-Gears/FishingGearsList.vue'
import FishingGearsCategoriesList from '../views/settings/Fishing-Gears/FishingGearsCategoriesList.vue'

// --Fishing Guides
import FishingGuides from '../views/settings/Fishing-Guides/FishingGuides.vue'
import Amenities from '../views/settings/Fishing-Guides/FishingGuides-Amenities.vue'
import Facilities from '../views/settings/Fishing-Guides/FishingGuides-Facilities.vue'
import FishingTechniques from '../views/settings/Fishing-Guides/FishingGuides-FishingTechniques.vue'
import Gears from '../views/settings/Fishing-Guides/FishingGuides-Gears.vue'
import ListingType from '../views/settings/Fishing-Guides/FishingGuides-ListingType.vue'
import Policies from '../views/settings/Fishing-Guides/FishingGuides-Policies.vue'
import FishSpecies from '../views/settings/FishSpecies.vue'
import FishingSpots from '../views/settings/FishingSpots.vue'

//Order Views
import OrderListing from '../views/orders/OrderListing.vue'
import LocationOrderDetails from '../views/orders/LocationOrderDetails.vue'
import GuideOrderDetails from '../views/orders/GuideOrderDetails.vue'

// CommunityPost Views
import CommunityPostListing from '../views/community-post/CommunityPostListing.vue'
import CommunityPostDetail from '../views/community-post/CommunityPostDetail.vue'
import CommunityPostForm from '../views/community-post/CommunityPostForm.vue'

// Email
import {
    CreateEmailTemplate,
    EmailTemplateListing,
    EmailsHistoryListing,
    ViewEmail,
} from '../views/email'

// Referrals
import { Referrals } from '../views/referrals'

// Groups Management View
import { GroupDetails, GroupsListing, GroupPostDetails } from '../views/groups'
// Tournaments Management
import { TournamentsListing, TournamentDetails } from '../views/tournaments'

// Moderator
import { ModeratorListing } from '../views/user-management/moderators'

import store from '../store'
import { APP_ROUTES } from '../helpers/routeHelpers'
import { UserRoles } from '../constants'

Vue.use(VueRouter)

let { Admin, Customer, Guest, Moderator, Vendor } = UserRoles

let currentUserType = Moderator
// let tempRedirect = ''

// let redirectionConfig = (type) => {
//     if (type == Admin) {
//         return {
//             path: '*',
//             redirect: APP_ROUTES.COMMUNITY_POSTS,
//         }
//     } else if (type == Moderator) {
//         return { path: '*', redirect: APP_ROUTES.COMMUNITY_POSTS }
//     }
// }

store.subscribe((mutation, state) => {
    currentUserType = state.auth.user.Type
    // tempRedirect = redirectionConfig(currentUserType)
})
let authRoutes = {
    path: '/auth',
    name: 'auth',
    component: Auth,
    redirect: APP_ROUTES.LOGIN,

    children: [
        {
            path: APP_ROUTES.LOGIN,
            component: AuthLogin,
            meta: {
                layout: 'full',
                pageTitle: 'Login',
                title: 'Login',
                requiresAuth: false,
                breadcrumb: [
                    {
                        text: 'Login',
                        active: true,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.FORGOT_PASSWORD,
            component: ForgetPassword,
            meta: {
                layout: 'full',
                pageTitle: 'Forgot Password',
                title: 'Forget Password',
                requiresAuth: false,
                breadcrumb: [
                    {
                        text: 'Forget Password',
                        active: true,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.RESET_PASSWORD,
            component: ResetPassword,
            props: true,
            meta: {
                layout: 'full',
                pageTitle: 'Login',
                title: 'Reset Password',
                requiresAuth: false,
                breadcrumb: [
                    {
                        text: 'Reset Password',
                        active: true,
                    },
                ],
            },
        },
    ],
    meta: {
        layout: 'full',
    },
}
let roleBasedRoutes = () => {
    let filtered = [
        {
            path: '/',
            redirect: APP_ROUTES.LOGIN,
        },
        {
            path: APP_ROUTES.DASHBOARD,
            name: 'home',
            component: Home,
            meta: {
                pageTitle: 'Dashboard',
                title: 'Dashboard',
                requiresAuth: true,
                // permissions: [Admin, Customer, Guest, Moderator, Vendor],
            },
        },

        // Moderator
        {
            path: APP_ROUTES.USER_MODERATOR,
            name: 'moderators',
            component: ModeratorListing,
            meta: {
                title: 'Moderators',
                pageTitle: 'Moderators',
                permissions: [Admin, Customer, Guest, Vendor],
                requiresAuth: true,
            },
        },
        // User Management Route
        {
            path: APP_ROUTES.USER_MANAGEMENT,
            component: UserManagement,
            name: 'user-management',
            meta: {
                // permissions: [Admin, Customer, Guest, Vendor],
                pageTitle: 'User Management',
                title: 'User Management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-management',
                        to: APP_ROUTES.USER_MANAGEMENT,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.EARNING,
            component: Earning,
            name: 'earning',
            meta: {
                // permissions: [Admin, Customer, Guest, Vendor],
                pageTitle: 'Earnings',
                title: 'Earnings',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'earning',
                        to: APP_ROUTES.EARNING,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.USER_DETAILS,
            name: 'User Details',
            component: UserDetails,
            meta: {
                // permissions: [Admin, Customer, Guest, Vendor],
                title: 'User Details',
                navActiveLink: 'user-management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-management',
                        text: 'User Management',
                        to: APP_ROUTES.USER_MANAGEMENT,
                    },
                    {
                        routeName: 'User Details',
                        text: 'Company Details',
                        to: APP_ROUTES.USER_DETAILS,
                        paramKey: 'userId',
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.USER_REVIEWS,
            name: 'Reviews',
            component: Reviews,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],
                title: 'Reviews',
                navActiveLink: 'user-management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-management',
                        text: 'User Management',
                        to: APP_ROUTES.USER_MANAGEMENT,
                    },
                    {
                        routeName: 'User Details',
                        text: 'User Details',
                        to: APP_ROUTES.USER_DETAILS,
                        paramKey: 'userId',
                    },
                    {
                        routeName: 'Reviews',
                        text: 'Reviews',
                        to: APP_ROUTES.USER_REVIEWS,
                    },
                ],
            },
        },

        {
            path: APP_ROUTES.USER_FISH_SPOT_DETAILS,
            name: 'UserFishSpotDetails',
            component: FishingSpotDetails,
            meta: {
                title: 'Fish Spot Details',
                navActiveLink: 'user-management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-management',
                        text: 'User Management',
                        to: APP_ROUTES.USER_MANAGEMENT,
                    },
                    {
                        routeName: 'User Details',
                        text: 'User Details',
                        to: APP_ROUTES.USER_DETAILS,
                        paramKey: 'userId',
                    },
                    {
                        routeName: 'Fish Spot Details',
                        text: 'Fish Spot Details',
                        to: APP_ROUTES.USER_FISH_SPOT_DETAILS,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.USER_FISH_GEAR_DETAILS,
            name: 'UserFishGearDetails',
            component: GearDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fish Spot Details',
                navActiveLink: 'user-management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-management',
                        text: 'User Management',
                        to: APP_ROUTES.USER_MANAGEMENT,
                    },
                    {
                        routeName: 'User Details',
                        text: 'User Details',
                        to: APP_ROUTES.USER_DETAILS,
                        paramKey: 'userId',
                    },
                    {
                        routeName: 'Fish Gear Details',
                        text: 'Fish Gear Details',
                        to: APP_ROUTES.USER_FISH_GEAR_DETAILS,
                    },
                ],
            },
        },
        // Fishing Guides Route
        {
            path: APP_ROUTES.FISHING_GUIDE,
            component: FishGuideListing,
            name: 'fish-guide',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Guides',
                pageTitle: 'Fishing Guides',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-guide',
                        to: APP_ROUTES.FISHING_GUIDE,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.FISHING_GUIDE_DETAILS,
            name: 'GuideDetails',
            component: GuideDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Guide Details',
                navActiveLink: 'fish-guide',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-guide',
                        text: 'Fishing Guide',
                        to: APP_ROUTES.FISHING_GUIDE,
                    },
                    {
                        routeName: 'GuideDetails',
                        text: 'Guide Details',
                        to: APP_ROUTES.FISHING_GUIDE_DETAILS,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.BOOKING_DETAILS,
            name: 'BookingDetails',
            component: BookingDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Booking Details',
                requiresAuth: true,
                navActiveLink: 'fish-guide',
                breadcrumb: [
                    {
                        routeName: 'fish-guide',
                        text: 'Fishing Guide',
                        to: APP_ROUTES.FISHING_GUIDE,
                    },
                    {
                        routeName: 'GuideDetails',
                        text: 'Guide Details',
                        to: APP_ROUTES.FISHING_GUIDE_DETAILS,
                        paramKey: 'guideId',
                    },
                    {
                        routeName: 'BookingDetails',
                        text: 'Booking Details',
                        to: APP_ROUTES.BOOKING_DETAILS,
                    },
                ],
            },
        },

        {
            path: APP_ROUTES.TRIP_DETAILS,
            name: 'Trip Details',
            component: TripDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                navActiveLink: 'fish-guide',
                title: 'Trip Details',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-guide',
                        text: 'Fishing Guide',
                        to: APP_ROUTES.FISHING_GUIDE,
                    },
                    {
                        routeName: 'GuideDetails',
                        text: 'Guide Details',
                        to: APP_ROUTES.FISHING_GUIDE_DETAILS,
                        paramKey: 'guideId',
                    },
                    {
                        routeName: 'Trip Details',
                        text: 'Trip Details',
                        to: APP_ROUTES.TRIP_DETAILS,
                        paramKey: 'tripId',
                    },
                ],
            },
        },
        // TRIP VIEW DETAILS ROUTE FROM TRIP LISTING
        {
            path: APP_ROUTES.BOOKING_TRIP_DETAILS,
            name: 'Trip Details',
            component: TripDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                navActiveLink: 'fish-guide',
                requiresAuth: true,
                title: 'Trip Details',
                breadcrumb: [
                    {
                        routeName: 'fish-guide',
                        text: 'Fishing Guide',
                        to: APP_ROUTES.FISHING_GUIDE,
                    },
                    {
                        routeName: 'GuideDetails',
                        text: 'Guide Details',
                        to: APP_ROUTES.FISHING_GUIDE_DETAILS,
                        paramKey: 'guideId',
                    },
                    {
                        routeName: 'BookingDetails',
                        text: 'Booking Details',
                        to: APP_ROUTES.BOOKING_DETAILS,
                        paramKey: 'bookingId',
                    },
                    {
                        routeName: 'Trip Details',
                        text: 'Trip Details',
                        to: APP_ROUTES.TRIP_DETAILS,
                    },
                ],
            },
        },

        // Fishing Spot Route
        {
            path: APP_ROUTES.FISH_SPOT,
            name: 'fish-spot',
            component: FishSpotListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Spots',
                pageTitle: 'Fishing Spots',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-spot',
                        to: APP_ROUTES.FISH_SPOT,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.FISH_SPOT_DETAILS,
            name: 'FishigSpotDetails',
            component: FishingSpotDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Spot Details',
                navActiveLink: 'fish-spot',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-spot',
                        text: 'Fishing Spot',
                        to: APP_ROUTES.FISH_SPOT,
                    },
                    {
                        routeName: 'FishigSpotDetails',
                        text: 'Spots',
                        to: APP_ROUTES.FISH_SPOT_DETAILS,
                    },
                ],
            },
        },
        // Fishing Gears Route
        {
            path: APP_ROUTES.FISH_GEAR,
            name: 'fish-gear',
            component: FishGearListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Gears',
                pageTitle: 'Fishing Gears',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'fish-gear',
                        to: APP_ROUTES.FISH_GEAR,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.FISH_GEAR_DETAILS,
            name: 'GearDetails',
            component: GearDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Gear Details',
                navActiveLink: 'fish-gear',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Fishing Gear',
                        routeName: 'fish-gear',
                        to: APP_ROUTES.FISH_GEAR,
                    },
                    {
                        text: 'Gear Details',
                        routeName: 'GearDetails',
                        to: APP_ROUTES.FISH_GEAR_DETAILS,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.USER_STORY_DETAILS,
            name: 'user-story-details',
            component: UserStoryDetail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'User Story',
                pageTitle: 'User Story Detail',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'user-story-detail',
                        to: APP_ROUTES.USER_STORY_DETAILS,
                    },
                ],
            },
        },
        // Order Listing
        {
            path: APP_ROUTES.ORDERS,
            name: 'order-listing',
            component: OrderListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Order Listing',
                pageTitle: 'Order Listing',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'order-listing',
                        to: APP_ROUTES.ORDERS,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.ORDER_DETAILS_LOCATION,
            name: 'Location',
            component: LocationOrderDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                navActiveLink: 'order-listing',
                requiresAuth: true,
                title: 'Location',

                breadcrumb: [
                    {
                        routeName: 'order-listing',
                        text: 'Order Listing',
                        to: APP_ROUTES.ORDERS,
                    },
                    {
                        routeName: 'Location',
                        text: 'Location',
                        to: APP_ROUTES.ORDER_DETAILS_LOCATION,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.ORDER_DETAILS_GUIDE,
            name: 'Guide Trip',
            component: GuideOrderDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                navActiveLink: 'order-listing',
                title: 'Guide Trip',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'order-listing',
                        text: 'Order Listing',
                        to: APP_ROUTES.ORDERS,
                    },
                    {
                        routeName: 'Guide Trip',
                        text: 'Guide Trip',
                        to: APP_ROUTES.ORDER_DETAILS_GUIDE,
                    },
                ],
            },
        },

        // Moderation Route

        {
            path: APP_ROUTES.MODERATION,
            name: 'moderation',
            component: Moderation,
            redirect: APP_ROUTES.MODERATION_USER_STORIES,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Moderations',
                pageTitle: 'Moderations',
                requiresAuth: true,
            },
            children: [
                {
                    path: APP_ROUTES.MODERATION_USER_STORIES,
                    component: ModerationUserStories,
                    meta: {
                        title: 'Moderations',
                        pageTitle: 'Moderations',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.MODERATION_MARKETPLACE,
                    component: ModerationMarketplace,
                    meta: {
                        title: 'Moderations',
                        pageTitle: 'Moderations',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.MODERATION_GROUP_POSTS,
                    component: ModerationGroups,
                    meta: {
                        title: 'Moderations',
                        pageTitle: 'Moderations',
                        requiresAuth: true,
                    },
                },
            ],
        },
        // Moderation User Stories Details
        {
            path: APP_ROUTES.MODERATION_USER_STORIES_DETAILS,
            name: 'Details',
            component: ModerationDetail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Details',
                navActiveLink: 'moderation',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'moderation',
                        text: 'Moderation',
                        to: APP_ROUTES.MODERATION,
                    },
                    {
                        text: 'User Stories',
                        to: APP_ROUTES.MODERATION_USER_STORIES,
                    },
                    {
                        routeName: 'Details',
                        text: 'Details',
                        to: APP_ROUTES.MODERATION_DETAILS,
                    },
                ],
            },
        },
        // Moderation MArketplace Details
        {
            path: APP_ROUTES.MODERATION_MARKETPLACE_DETAILS,
            name: 'Details',
            component: ModerationDetail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Details',
                navActiveLink: 'moderation',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'moderation',
                        text: 'Moderation',
                        to: APP_ROUTES.MODERATION,
                    },
                    {
                        text: 'Marketplace',
                        to: APP_ROUTES.MODERATION_MARKETPLACE,
                    },
                    {
                        routeName: 'Details',
                        text: 'Details',
                        to: APP_ROUTES.MODERATION_DETAILS,
                    },
                ],
            },
        },
        // Moderation Groups Details
        {
            path: APP_ROUTES.MODERATION_GROUP_POSTS_DETAILS,
            name: 'Details',
            component: ModerationDetail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Details',
                navActiveLink: 'moderation',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'moderation',
                        text: 'Moderation',
                        to: APP_ROUTES.MODERATION,
                    },
                    {
                        text: 'Groups',
                        to: APP_ROUTES.MODERATION_GROUP_POSTS,
                    },
                    {
                        routeName: 'Details',
                        text: 'Details',
                        to: APP_ROUTES.MODERATION_DETAILS,
                    },
                ],
            },
        },
        // Reports Route
        {
            path: APP_ROUTES.REPORT_SUBSCRIPTION,
            name: 'report-subscription',
            component: SubscriptionReport,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Subscription Earning',
                pageTitle: 'Subscription Earning',
                requiresAuth: true,
            },
        },
        {
            path: APP_ROUTES.REPORT_TRANSACTION,
            name: 'report-transaction',
            component: TransactionReport,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Transactions Earning',
                pageTitle: 'Transactions Earning',
                requiresAuth: true,
            },
        },
        {
            path: APP_ROUTES.REPORT_SOS,
            name: 'report-sos',
            component: SOSReport,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Sos',
                pageTitle: 'SOS',
                requiresAuth: true,
            },
        },
        // Monthly Submissions Route --> User Stories Route

        // {
        //     path: APP_ROUTES.MONTHLY_SUBMISSIONS,
        //     name: 'monthly-submissions',
        //     component: MonthlySubmissions,
        //     redirect: APP_ROUTES.MONTHLY_SUBMISSIONS_REQUESTS,
        //     meta: {
        //         title: 'Monthly Submissions',
        //         pageTitle: 'Monthly Submissions',
        //         requiresAuth: true,
        //     },
        //     children: [
        //         {
        //             path: APP_ROUTES.MONTHLY_SUBMISSIONS_REQUESTS,
        //             component: SubmissionRequests,
        //             meta: {
        //                 title: 'Monthly Submissions',
        //                 pageTitle: 'Monthly Submissions',
        //                 requiresAuth: true,
        //             },
        //         },
        //         {
        //             path: APP_ROUTES.MONTHLY_SUBMISSIONS_ACCEPTED,
        //             component: AcceptedSubmissions,
        //             meta: {
        //                 title: 'Monthly Submissions',
        //                 pageTitle: 'Monthly Submissions',
        //                 requiresAuth: true,
        //             },
        //         },
        //         {
        //             path: APP_ROUTES.MONTHLY_SUBMISSIONS_PREVIOUS,
        //             component: PreviousSubmissions,
        //             meta: {
        //                 title: 'Monthly Submissions',
        //                 pageTitle: 'Monthly Submissions',
        //                 requiresAuth: true,
        //             },
        //         },
        //     ],
        // },

        // User Stories Route
        {
            path: APP_ROUTES.USER_STORIES,
            name: 'user-stories',
            component: UserStories,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                pageTitle: 'User Stories',
                title: 'User Stories',
                requiresAuth: true,
            },
        },

        // Rating And Review Route
        {
            path: APP_ROUTES.RATING_REVIEWS,
            component: RatingReviews,
            name: 'rating-reviews',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                pageTitle: 'Rating & Reviews',
                title: 'Rating & Reviews',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'rating-reviews',
                        to: APP_ROUTES.RATING_REVIEWS,
                    },
                ],
            },
        },

        {
            path: APP_ROUTES.RATING_REVIEWS_DETAILS,
            name: 'Reviews',
            component: SellerReview,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Reviews',
                requiresAuth: true,
                navActiveLink: 'rating-reviews',
                breadcrumb: [
                    {
                        routeName: 'rating-reviews',
                        text: 'Rating & Reviews',
                        to: APP_ROUTES.RATING_REVIEWS,
                    },
                    {
                        routeName: 'Review',
                        text: 'Review',
                        to: APP_ROUTES.RATING_REVIEWS_DETAILS,
                    },
                ],
            },
        },
        // Setting Routes
        {
            path: APP_ROUTES.SETTINGS_CONFIGURATION,
            name: 'configuration-settings',
            component: ConfigurationSettings,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Configuration Settings',
                pageTitle: 'Configuration Settings',
                requiresAuth: true,
            },
        },
        {
            path: APP_ROUTES.SETTINGS_FISH_GEAR,
            name: 'fishing-gears',
            component: FishingGears,
            redirect: APP_ROUTES.SETTINGS_FISH_GEAR_CATEGORIES,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Gears',
                pageTitle: 'Fishing Gears',
                requiresAuth: true,
            },
            children: [
                {
                    path: APP_ROUTES.SETTINGS_FISH_GEAR_LIST,
                    component: FishingGearsList,
                    meta: {
                        title: 'Fishing Gear',
                        pageTitle: 'Fishing Gear',
                        requiresAuth: true,
                    },
                },

                {
                    path: APP_ROUTES.SETTINGS_FISH_GEAR_CATEGORIES,
                    component: FishingGearsCategoriesList,
                    meta: {
                        title: 'Fishing Gear',
                        pageTitle: 'Fishing Gear',
                        requiresAuth: true,
                    },
                },
            ],
        },
        {
            path: APP_ROUTES.SETTINGS_FISH_SPECIES,
            name: 'fish-species',
            component: FishSpecies,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fish Species',
                pageTitle: 'Fish Species',
                requiresAuth: true,
            },
        },
        {
            path: APP_ROUTES.SETTINGS_FISH_SPOTS,
            name: 'fishing-spots',
            component: FishingSpots,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Spots',
                pageTitle: 'Fishing Spots',
                requiresAuth: true,
            },
        },
        {
            path: APP_ROUTES.SETTINGS_FISH_GUIDES,
            name: 'fishing-guides',
            component: FishingGuides,
            redirect: APP_ROUTES.SETTINGS_FISH_GUIDES_LISTING_TYPE,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Fishing Guides',
                pageTitle: 'Fishing Guides',
                requiresAuth: true,
            },
            children: [
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_AMENITIES,
                    component: Amenities,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_FACILITIES,
                    component: Facilities,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_TECHNIQUES,
                    component: FishingTechniques,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_GEARS,
                    component: Gears,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_LISTING_TYPE,
                    component: ListingType,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
                {
                    path: APP_ROUTES.SETTINGS_FISH_GUIDES_POLICIES,
                    component: Policies,
                    meta: {
                        title: 'Fishing Guides',
                        pageTitle: 'Fishing Guides',
                        requiresAuth: true,
                    },
                },
            ],
        },

        // Community
        {
            path: APP_ROUTES.COMMUNITY_POSTS,
            component: CommunityPostListing,
            name: 'community-post',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'Community Posts',
                pageTitle: 'Community Posts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                ],
            },
        },

        {
            path: '/community-post/news',
            component: CommunityPostListing,
            name: 'community-post',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'Community Posts',
                pageTitle: 'Community Posts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                ],
            },
        },
        {
            path: '/community-post/faqs',
            component: CommunityPostListing,
            name: 'community-post',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'Community Posts',
                pageTitle: 'Community Posts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                ],
            },
        },
        {
            path: '/community-post/announcements',
            component: CommunityPostListing,
            name: 'community-post',
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'Community Posts',
                pageTitle: 'Community Posts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                ],
            },
        },

        {
            path: APP_ROUTES.COMMUNITY_POST_DETAILS,
            name: 'community-post-details',
            component: CommunityPostDetail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                navActiveLink: 'community-post',
                title: 'Community Posts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        text: 'Community Posts',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                    {
                        routeName: 'community-post-details',
                        text: 'Community Post Details',
                        to: APP_ROUTES.COMMUNITY_POST_DETAILS,
                    },
                ],
            },
        },

        {
            path: APP_ROUTES.CREATE_COMMUNITY_POST,
            name: 'create-community-post',
            component: CommunityPostForm,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'New Community Post',
                requiresAuth: true,
                navActiveLink: 'community-post',
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        text: 'Community Posts',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                    {
                        routeName: 'create-community-post',
                        text: 'Create New Post',
                        to: APP_ROUTES.CREATE_COMMUNITY_POST,
                    },
                ],
            },
        },
        {
            path: '/community-post/update/:id',
            name: 'update-community-post',
            component: CommunityPostForm,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                title: 'Edit Community Post',
                requiresAuth: true,
                navActiveLink: 'community-post',
                breadcrumb: [
                    {
                        routeName: 'community-post',
                        text: 'Community Posts',
                        to: APP_ROUTES.COMMUNITY_POSTS,
                    },
                    {
                        routeName: 'update-community-post',
                        text: 'Edit Community Post',
                        to: '/community-post/update/:id',
                    },
                ],
            },
        },

        // Refferals
        {
            path: APP_ROUTES.REFERRALS,
            name: 'referrals',
            component: Referrals,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                pageTitle: 'Referral Code',
                title: 'Referral Code',
                requiresAuth: true,
            },
        },

        //Emails

        // Email Template Listing

        {
            path: APP_ROUTES.EMAIL_TEMPLATE_LISTING,
            name: 'email-templates',
            component: EmailTemplateListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Email Templates',
                title: 'Email Templates',
                requiresAuth: true,
            },
        },

        // View Email Template
        {
            path: APP_ROUTES.VIEW_EMAIL_TEMPLATE_DETAILS,
            name: 'email-templates',
            component: ViewEmail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Details',
                title: 'Details',
                requiresAuth: true,
            },
        },

        // Create Email Template

        {
            path: APP_ROUTES.CREATE_EMAIL_TEMPLATE,
            // name: 'create-email-template',
            name: 'email-templates',
            component: CreateEmailTemplate,

            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Create Email Template',
                title: 'Create Email Template',
                requiresAuth: true,
            },
        },

        // Edit Email Template
        {
            path: APP_ROUTES.EDIT_EMAIL_TEMPLATE,
            // name: 'edit',
            name: 'email-templates',
            component: CreateEmailTemplate,

            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Edit Email Template',
                title: 'Edit Email Template',
                requiresAuth: true,
            },
        },

        // Email History Listing

        {
            path: APP_ROUTES.EMAILS_HISTORY_LISTING,
            name: 'emails-history',
            component: EmailsHistoryListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Email History',
                title: 'Email History',
                requiresAuth: true,
            },
        },

        // View Email History

        {
            path: APP_ROUTES.VIEW_EMAIL_TEMPLATE_HISTORY_DETAILS,
            name: 'emails-history',
            component: ViewEmail,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor, Moderator],

                pageTitle: 'Details',
                title: 'Details',
                requiresAuth: true,
            },
        },

        // {
        //     path: APP_ROUTES.VIEW_EMAIL_TEMPLATE_DETAILS,
        //     name: 'email-templates',
        //     component: ViewEmail,
        //     meta: {
        //         title: 'Email Details',

        //         requiresAuth: true,
        //         breadcrumb: [
        //             {
        //                 routeName: 'email-templates',
        //                 text: 'Email Templates',
        //                 to: APP_ROUTES.EMAIL_TEMPLATE_LISTING,
        //             },
        //             {
        //                 routeName: 'email',
        //                 text: 'View Details',
        //                 to: APP_ROUTES.VIEW_EMAIL_TEMPLATE_DETAILS,
        //             },
        //         ],
        //     },
        // },

        // {
        //     path: APP_ROUTES.VIEW_EMAIL_TEMPLATE_HISTORY_DETAILS,
        //     name: 'emails-history',
        //     component: ViewEmail,
        //     meta: {
        //         title: 'View Details',
        //         requiresAuth: true,
        //         breadcrumb: [
        //             {
        //                 routeName: 'emails-history',
        //                 text: 'Emails History',
        //                 to: APP_ROUTES.EMAILS_HISTORY_LISTING,
        //             },
        //             {
        //                 routeName: 'email-history',
        //                 text: 'View Details',
        //                 to: APP_ROUTES.VIEW_EMAIL_TEMPLATE_HISTORY_DETAILS,
        //             },
        //         ],
        //     },
        // },

        // Groups

        {
            path: APP_ROUTES.GROUPS_LISTING,
            name: 'groups',
            component: GroupsListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Groups Management',
                pageTitle: 'Groups Management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'groups',
                        to: APP_ROUTES.GROUPS_LISTING,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.GROUP_DETAILS,
            name: 'GroupDetails',
            component: GroupDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Group Details',
                navActiveLink: 'group',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Groups Management',
                        routeName: 'groups',
                        to: APP_ROUTES.GROUPS_LISTING,
                    },
                    {
                        text: 'Details',
                        routeName: 'GroupDetails',
                        to: APP_ROUTES.GROUP_DETAILS,
                    },
                ],
            },
        },
        // Group Post

        {
            path: APP_ROUTES.GROUP_POST_DETAILS,
            name: 'group-post',
            component: GroupPostDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],

                title: 'Groups Post',
                pageTitle: 'Groups Post',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'group-post',
                        to: APP_ROUTES.GROUP_POST_DETAILS,
                    },
                ],
            },
        },

        // Tournaments

        {
            path: APP_ROUTES.TOURNAMENTS_LISTING,
            name: 'tournaments',
            component: TournamentsListing,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],
                title: 'Tournaments Management',
                pageTitle: 'Tournaments Management',
                requiresAuth: true,
                breadcrumb: [
                    {
                        routeName: 'tournaments',
                        to: APP_ROUTES.TOURNAMENTS_LISTING,
                    },
                ],
            },
        },
        {
            path: APP_ROUTES.TOURNAMENT_DETAILS,
            name: 'TournamentDetails',
            component: TournamentDetails,
            meta: {
                permissions: [Admin, Customer, Guest, Vendor],
                title: 'Tournament Details',
                navActiveLink: 'tournaments',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Tournament Management',
                        routeName: 'tournament',
                        to: APP_ROUTES.TOURNAMENTS_LISTING,
                    },
                    {
                        text: 'Details',
                        routeName: 'TournamentDetails',
                        to: APP_ROUTES.TOURNAMENT_DETAILS,
                    },
                ],
            },
        },

        // 404

        // {
        //     path: '*',
        //     redirect: APP_ROUTES.COMMUNITY_POSTS,
        //     meta: {
        //         permissions: [Moderator],
        //     },
        // },
        // {
        //     path: '*',
        //     redirect: APP_ROUTES.DASHBOARD,
        //     meta: {
        //         permissions: [Admin],
        //     },
        // },
    ]
    filtered.filter((item) =>
        item?.meta?.permissions?.includes(currentUserType)
    )
    // let temp = {
    //     path: '*',
    //     redirect:
    //         currentUserType == Admin
    //             ? APP_ROUTES.DASHBOARD
    //             : APP_ROUTES.COMMUNITY_POSTS,
    // }

    // filtered = [...filtered, { ...temp }]

    return filtered
}

let roleBasedRoutesObjs = roleBasedRoutes()

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { ...authRoutes },
        ...roleBasedRoutesObjs,
        {
            path: '*',
            redirect: APP_ROUTES.DASHBOARD,
        },
    ],
})

//For splash screen
const DEFAULT_TITLE = 'FishFin'

router?.beforeEach((to, from, next) => {
    sessionStorage.params = JSON.stringify(to?.params)

   if (store?.state?.auth?.isAuthenticated && !to?.meta?.requiresAuth) {
        next(APP_ROUTES.DASHBOARD)
    } else if (!store?.state?.auth?.isAuthenticated && to?.meta?.requiresAuth) {
        next(APP_ROUTES.LOGIN)
    }
    next()
})

router.afterEach((to, from) => {
    // To set tab title
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
