import { injectBaseConstantMethods } from "./BaseConstants";

export const SubscriptionStatus = {
    PAID: "PAID",
    FAILED: "FAILED",
};

const displayTextKeys = {
    [SubscriptionStatus.PAID]: "Paid",
    [SubscriptionStatus.FAILED]: "Failed",
};

const labelClass = {
    [SubscriptionStatus.PAID]: "light-success",
    [SubscriptionStatus.FAILED]: "light-danger",
};

export default injectBaseConstantMethods(
    SubscriptionStatus,
    displayTextKeys,
    labelClass
);
