import { render, staticRenderFns } from "./GroupedAvatar.vue?vue&type=template&id=78d6f321&scoped=true&"
import script from "./GroupedAvatar.vue?vue&type=script&lang=js&"
export * from "./GroupedAvatar.vue?vue&type=script&lang=js&"
import style0 from "./GroupedAvatar.vue?vue&type=style&index=0&id=78d6f321&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d6f321",
  null
  
)

export default component.exports