<template>
	<div class="winnig-container m-0 p-0">
		<div
			v-for="(item, index) in winnerConfig"
			:key="index"
			:class="`d-flex inner-container justify-content-between align-items-center ${
				item.winningPosition == FIRST_POSITION && 'yellow-box'
			}`"
		>
			<div class="winning-icon-positions d-flex align-items-center">
				<b-img
					:src="winningTrophy"
					alt="winning-trophy"
					width="15px"
					height="15px"
					class="winning-trophy-image p-0"
				/>
				<p class="text font-weight-normal m-0 p-0">
					{{ numToWordsCount[item.winningPosition] }}
					{{ WINNER_TEXT }}
				</p>
			</div>

			<div class="winner-user d-flex align-items-center ml-3 mr-3">
				<div v-if="item.winningUser">
					<user-info
						iconSize="md"
						:avatarImage="getMediaPath(item.winningUser.Media)"
						:fullName="`${item.winningUser.FullName || '-'}`"
						containerClasses="mb-8"
						nameClasses="marginT"
					/>
				</div>
			</div>

			<div class="winning-prize">
				<span class="font-weight-bolder font-black">
					{{ item.winningCurrency }}
					{{ item.winningPrizeAmount.toFixed(2) }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import winningTrophy from '@/assets/images/gallery/winning-trophy.svg'
import UserInfo from '../../components/UserInfo.vue'
import { getMediaPath } from '../../utils/commonUtils'
import { numToWordsCount } from '../../constants'

export default {
	name: 'Winning',
	props: ['winnerConfig'],
	components: {
		BImg,
		UserInfo,
	},
	data() {
		return {
			FIRST_POSITION: 1,
			WINNER_TEXT: 'Winner',
			winningTrophy,
			numToWordsCount,
		}
	},
	methods: {
		getMediaPath,
	},
}
</script>

<style  scoped>
.inner-container {
	/* max-width: 400px; */
	padding: 5px;
	width: fit-content;
}
.yellow-box {
	border: 1px solid #ff7600;
	border-radius: 6px;
}
.winning-trophy-image {
	margin: 0 7px 0 0;
}

.font-black {
	color: black;
}
</style>