<template>
	<b-card>
		<b-container fluid>
			<b-row>
				<b-col cols="12" md="6" lg="5" sm="12">
					<LabelText
						label="Reported By"
						showRightSection
						textClasses="font-weight-bolder"
					>
						<UserInfo
							iconSize="md"
							:fullName="currentReport.User.FullName || '-'"
							:avatarImage="
								getMediaPath(currentReport.User.Media) || '-'
							"
						/>
					</LabelText>

					<LabelText
						label="Reported Person"
						showRightSection
						textClasses="font-weight-bolder"
					>
						<UserInfo
							iconSize="md"
							:fullName="
								currentReport.ReportedPerson.FullName || '-'
							"
							:avatarImage="
								getMediaPath(
									currentReport.ReportedPerson.Media
								) || '-'
							"
						/>
					</LabelText>

					<LabelText
						label="Date"
						:text="
							convertDateTime({
								date: currentReport.CreatedAt,
								customFormat:
									dateTimeFormat.dateWithYearMonthFormat,
								dateOnly: true,
							})
						"
						textClasses="font-weight-bolder"
					/>

					<div v-if="currentReport.ReportType == 6">
						<p class="review-title">Report:</p>
						<p class="font-weight-bolder report">
							{{ currentReport.Description || 'No Content' }}
						</p>
					</div>
				</b-col>
				<b-col cols="12" md="6" lg="4" sm="12">
					<!-- <LabelText
					:label="currentReport.Type"
					text="Dallas, US"
					textClasses="font-weight-bolder"
          /			> -->
					<div v-if="!currentReport.isHideLocation">
						<LabelText
							label="Location"
							:text="
								currentReport.EntityType ==
								ReportEntityType.Location
									? currentReport.Location.Address
									: currentReport.Location || '-'
							"
							textClasses="font-weight-bolder"
						/>
					</div>
					<div v-if="currentReport.isHideLocation">
						<LabelText
							label="Group Name"
							:text="currentReport.GroupName || '-'"
							textClasses="font-weight-bolder"
						/>
					</div>

					<LabelText
						label="Report Type"
						:text="
							reportType.getDisplayTextKey(
								currentReport.ReportType
							)
						"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						v-if="
							currentReport.EntityType ==
							ReportEntityType.GroupPostComment
						"
						:label="currentReport.Type"
						:text="currentReport.GroupPostComment.Comment"
						textClasses="font-weight-bolder"
					/>

					<!-- <LabelText
            label="Price"
            text="$100"
            textClasses="font-weight-bolder"
          /> -->
				</b-col>
				<b-col cols="12" md="12" lg="3" sm="12">
					<div class="mb-2 text-right text-md-left">
						<Button
							buttonText="Remove From Listing"
							buttonVariant="danger"
							v-if="
								currentReport.Status === reportStatus.ToBeViewed
							"
							classes="mt-1 mb-1 w-100"
							v-b-modal="`action-${currentReport.Id}-modal`"
							:clickHandler="
								() =>
									setModalStatus(reportStatus.ListingRemoved)
							"
						/><Button
							buttonText="Ignore"
							buttonVariant="outline"
							v-if="
								currentReport.Status === reportStatus.ToBeViewed
							"
							classes="mt-1 mb-1 w-100 btn-outline-danger"
							v-b-modal="`action-${currentReport.Id}-modal`"
							:clickHandler="
								() =>
									setModalStatus(reportStatus.ReportRejected)
							"
						/>
					</div>
				</b-col>
			</b-row>
			<hr />
			<!-- Buttons Row -->
			<b-row>
				<b-col cols="12" md="12" lg="3" sm="12">
					<Button
						buttonText="View Reporter Profile"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="
							() => viewUserDetails(currentReport.User.Id)
						"
					/>
				</b-col>
				<b-col cols="12" md="12" lg="3" sm="12">
					<Button
						buttonText="Reported Person Profile"
						buttonVariant="outline"
						classes="mt-1 mb-1 w-100 btn-outline-grey"
						:clickHandler="
							() =>
								viewUserDetails(currentReport.ReportedPerson.Id)
						"
					/>
				</b-col>
				<b-col
					cols="12"
					md="12"
					lg="3"
					sm="12"
					v-if="currentReport.EntityType != 15"
				>
					<div
						v-if="
							!isHideViewDetailsBtn &&
							currentReport.Status != reportStatus.ListingRemoved
						"
					>
						<Button
							:buttonText="viewDetailsBtnText"
							buttonVariant="outline"
							classes="mt-1 mb-1 w-100 btn-outline-grey"
							:clickHandler="viewDetails"
						/>
					</div>
				</b-col>
				<ConfirmModal
					:isLoading="isLoading"
					:isSuccess="isModerationStatusChangedSuccess"
					:onOkayPress="handleOkayPress"
					successText="Success !"
					:onYesPress="() => updateModerationStatus(status)"
					:id="`action-${currentReport.Id}-modal`"
					:messageText="`Are you sure you want to ${statusText} this ${updateMessage} ?`"
					:messageIcon="
						require('@/assets/images/icons/restrict-icon.svg')
					"
				/>
			</b-row>
		</b-container>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
} from 'bootstrap-vue'
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	ref,
} from '@vue/composition-api'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import AvatarImage from '../../assets/images/avatars/8.png'
import Button from '../../components/Button.vue'
import Avatar from '../../components/Avatar.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import UserInfo from '@/components/UserInfo.vue'
import { getModerationDetail } from '../../api/api.service'
import { useStore } from '../../store'
import { getMediaPath, showModal } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { ReportStatus, ReportType, ReportEntityType } from '../../constants'
import { FrownIcon } from 'vue-feather-icons'

export default {
	name: 'ModerationDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BAvatar,
		LabelText,
		Ratings,
		Button,
		Avatar,
		ConfirmModal,
		UserInfo,
	},
	setup: (props, context) => {
		const reportId = Number(context.root.$route.params.moderationId)
		const store = useStore()
		const isHideViewDetailsBtn = ref(false)

		const state = reactive({
			viewDetailsBtnText: 'View Details',
			currentReport: {},
			isLoading: false,
			isModerationStatusChangedSuccess: false,
			status: null,
			updateMessage: '',
			statusText: '',
			comment: '',
		})

		const generateRouteConfig = () => {
			return {
				[ReportEntityType.User]: 'User',
				[ReportEntityType.FishSpecie]: 'Fish Species',
				[ReportEntityType.FishSpot]: 'Fish Spot',
				[ReportEntityType.CatchLog]: 'Catchlog',
				[ReportEntityType.Booking]: 'Booking',
				[ReportEntityType.Voucher]: 'Voucher',
				[ReportEntityType.CommunityPost]: 'Community Post',
				[ReportEntityType.ConfigSetting]: 'Config Setting',
				[ReportEntityType.UserStoryTag]: 'User Story Comment',

				[ReportEntityType.Gear]: {
					route: APP_ROUTES.FISH_GEAR_DETAILS,
					paramsObj: {
						gearId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.Location]: {
					route: APP_ROUTES.FISH_SPOT_DETAILS,
					paramsObj: {
						fishSpotId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.GuideTrip]: {
					route: APP_ROUTES.FISHING_GUIDE_DETAILS,
					paramsObj: {
						guideId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.UserStory]: {
					route: APP_ROUTES.USER_STORY_DETAILS,
					paramsObj: {
						storyId: state.currentReport.EntityId,
					},
				},

				[ReportEntityType.Group]: {
					route: APP_ROUTES.GROUP_DETAILS,
					paramsObj: {
						groupId: state.currentReport.EntityId,
					},
				},
				[ReportEntityType.GroupPost]: {
					route: APP_ROUTES.GROUP_POST_DETAILS,
					paramsObj: {
						postId: state?.currentReport?.EntityId,
					},
				},
				[ReportEntityType.GroupPostComment]: {
					route: APP_ROUTES.GROUP_POST_DETAILS,
					paramsObj: {
						postId: state?.currentReport?.GroupPostComment
							?.GroupPostId,
					},
				},
			}
		}
		let routesConfig = generateRouteConfig()

		function extractReportDetail(report) {
			if (!Object.keys(report).length) {
				return {}
			}

			let Title = ''
			let GroupName = ''
			let ReportedPerson = {}
			let Type = ''
			let isHideLocation = false

			if (report.GuideTrip) {
				Title = report.GuideTrip.Title
				ReportedPerson = report.GuideTrip.User
				Type = 'Fishing Trip'
				state.viewDetailsBtnText = 'View Trip Details'
				state.updateMessage = 'Trip'
			} else if (report.Location) {
				Title = report.Location.Title || report.Location.Address
				ReportedPerson = report.Location.User
				Type = 'Fishing Spot'
				state.viewDetailsBtnText = 'View Location Details'
				state.updateMessage = 'Location'
			} else if (report.UserStory) {
				Title = report.UserStory.Content
				ReportedPerson = report.UserStory.User
				Type = 'User Story'
				state.viewDetailsBtnText = 'View Story'
				state.updateMessage = 'User Story'
			} else if (report.UserStoryTag) {
				Title = '-'
				ReportedPerson = report.UserStoryTag.User
				Type = 'User Story Comment'
				state.viewDetailsBtnText = 'View Story Comment'
				state.updateMessage = 'User Story Comment'
			} else if (report.Group) {
				GroupName = report.Group.Name
				Title = report.Group.Name
				ReportedPerson = report.Group.User
				Type = 'Group'
				state.viewDetailsBtnText = 'View Group Details'
				isHideLocation = true
				state.updateMessage = 'Group'
			} else if (report.GroupPost) {
				GroupName = report.GroupPost.Group.Name
				// Title = '-'
				ReportedPerson = report.GroupPost.User
				Type = 'Group Post'
				isHideLocation = true
				state.updateMessage = 'Group Post'
			} else if (report.GroupPostComment) {
				GroupName = report.GroupPostComment.GroupPost.Group.Name || '-'
				Title = report.GroupPostComment.Comment
				ReportedPerson = report.GroupPostComment.User
				Type = 'Group Post Comment'
				state.viewDetailsBtnText = 'View Post'
				isHideLocation = true
				state.updateMessage = 'Comment'
				isHideViewDetailsBtn.value = true
			} else if (report.Gear) {
				Title = report.Gear.Name
				ReportedPerson = report.Gear.User
				Type = 'Fishing Gear'
				state.viewDetailsBtnText = 'View Product Details'
				state.updateMessage = 'Gear'
			}

			return {
				...report,
				Title,
				GroupName,
				Type,
				ReportedPerson: ReportedPerson || {},
				isHideLocation,
			}
		}

		// state.currentReport = extractReportDetail(
		// 	computed(() =>
		// 		store.getters['moderation/getModerationById'](reportId)
		// 	).value || {}
		// )

		generateUserStoryTagComment()

		onMounted(() => {
			getModerationDetail(reportId).then((res) => {
				state.currentReport = { ...extractReportDetail(res.Report) }
				routesConfig = generateRouteConfig()
				generateUserStoryTagComment()
			})
		})

		function viewUserDetails(id) {
			context.root.$router.push(
				getRoute(APP_ROUTES.USER_DETAILS, { userId: id })
			)
		}

		function generateUserStoryTagComment() {
			let userObj = {}
			if (
				state.currentReport.EntityType == 15 &&
				state.currentReport.UserStoryTag
			) {
				state.comment = state.currentReport.UserStoryTag.Comment
				if (state.currentReport.UserStoryTag.TagUser) {
					userObj[state.currentReport.UserStoryTag.TagUserId] =
						state.currentReport.UserStoryTag.TagUser.FullName
				}
				if (state.currentReport.UserStoryTag.RefTags) {
					state.currentReport.UserStoryTag.RefTags.forEach((item) => {
						userObj[item.TagUserId] = item.TagUser.FullName
					})
				}

				Object.keys(userObj).forEach((key) => {
					state.comment = state.comment.replaceAll(
						`$#{${key}}#$`,
						userObj[key]
					)
				})
			}
		}

		function setModalStatus(status) {
			state.status = status
			if (status === ReportStatus.ListingRemoved) {
				state.statusText = 'remove'
			} else {
				state.statusText = 'ignore'
			}

			showModal(`action-${state.currentReport.Id}-modal`, context)
		}

		const viewDetails = () => {
			let desiredRouteDetails =
				routesConfig[state?.currentReport?.EntityType]
			context.root.$router.push(
				getRoute(desiredRouteDetails?.route, {
					...desiredRouteDetails?.paramsObj,
				})
			)
		}

		function updateModerationStatus(status) {
			state.isLoading = true
			store
				.dispatch('moderation/updateModerationStatus', {
					id: reportId,
					status,
				})
				.then((res) => {
					state.currentReport = {
						...state.currentReport,
						Status: status,
					}
					state.isModerationStatusChangedSuccess = true
				})
				.finally(() => {
					state.isLoading = false
				})
		}

		function handleOkayPress() {
			state.isModerationStatusChangedSuccess = false
		}
		return {
			...toRefs(state),
			AvatarImage,
			dateTimeFormat,
			reportType: ReportType,
			reportStatus: ReportStatus,
			ReportEntityType,
			getMediaPath,
			convertDateTime,
			viewUserDetails,
			viewDetails,
			updateModerationStatus,
			handleOkayPress,
			setModalStatus,
			showModal,
			isHideViewDetailsBtn,
		}
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.report {
	line-height: 25px;
	color: black;
}
.review-title {
	color: #888888;
}
</style>