<template>
  <div class="mt-2">
    <b-card body-class="card-body-style p-0">
      <div class="p-1">
        <search-header
          :showSearchField="false"
          :showStatusFilter="false"
          :pageLimitValue="pageLimit"
          :onPageLimitChange="handlePageLimitChange"
          :selectedPageValue="page"
          :perPage="pageLimit"
          :onHandlePageChange="handlePageChange"
          classes="margin-bottom-only"
        >
          <template #left-header-content>
            <span class="search-label mr-1">Search</span>
            <b-form-input
              class="d-inline-block w-25"
              @input="handleSearchChange"
              :value="searchText"
            />
          </template>
          <template #right-header-content>
            <Button
              buttonVariant="outline-primary"
              buttonText="+ Add New Spot"
              :clickHandler="handleAddClick"
            />
          </template>
        </search-header>
      </div>
      <data-table
        :perPageLimit="pageLimit"
        entriesName="Fish Spots"
        :showPagination="true"
        :config="config"
        :data="data"
        :totalRecords="total"
        :isLoading="isFetching"
        :perPage="data.length"
        :onHandlePageChange="handlePageChange"
        :selectedPageValue="page"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(No)="data">
          <span class="font-weight-bolder">{{ data.item.Id }}</span>
        </template>
        <template #cell(LocationName)="data">
          <span class="font-weight-bolder">{{ data.item.Address || "-" }}</span>
        </template>
        <template #cell(State)="data">
          <span class="font-weight-bolder">{{ data.item.State || "-" }}</span>
        </template>
        <template #cell(Country)="data">
          <span class="font-weight-bolder">{{ data.item.Country || "-" }}</span>
        </template>
        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-b-tooltip.hover.top="'Edit'"
              icon="EditIcon"
              size="16"
              class="mr-3 detail-icon cursor-pointer"
              v-b-modal="modalId"
              v-on:click="handleEditClick(data)"
            />
            <b-tooltip
              title="Details"
              :target="`details-row-${data.item}-detail-icon`"
            />

            <feather-icon
              v-b-modal="'delete-modal'"
              :icon="`Trash2Icon`"
              class="delete-icon cursor-pointer"
              size="16"
              @click="handleDeleteClick(data.item.Id)"
              v-b-tooltip.hover.top="'Delete'"
            />
          </div>
        </template>
      </data-table>
    </b-card>
    <BasicModal
      id="modal-1"
      :modalTitle="isEdit ? 'Edit Fishing Spot' : 'Add New Fishing Spot'"
    >
      <template #showForm>
        <div>
          <validation-observer ref="validationRules">
            <b-form @submit.prevent="validateForm">
              <b-form-group
                label-cols="4"
                label="Location Name"
                label-for="locaiton-name"
                label-size="md"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:5|max:30|regex:^[a-zA-Z0-9 ]*$"
                  name="Location Name"
                >
                  <google-places-input
                    placeholder="Type Location Name"
                    id="location-name"
                    size="md"
                    classes="w-100"
                    v-model="locationName"
                    :errors="errors"
                    :onChange="handleLocationSelect"
                  >
                  </google-places-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="State"
                label-for="state-name"
                label-size="md"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="State Name"
                >
                  <Input
                    placeholder="Type State Name"
                    id="state-name"
                    size="md"
                    classes="w-100"
                    v-model="stateName"
                    :errors="errors"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Country Name:"
                label-for="country-name"
                label-size="md"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Country Name"
                >
                  <Input
                    placeholder="Type Country Name"
                    id="country-name"
                    size="md"
                    classes="w-100"
                    v-model="countryName"
                    :errors="errors"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="d-flex justify-content-between">
                <div class="w-50 mr-1">
                  <Button
                    buttonVariant="danger"
                    buttonText="Delete"
                    classes="w-100"
                    v-if="isEdit"
                    :isLoading="isDeleting"
                    :clickHandler="deletefishSpot"
                  />
                </div>
                <div class="w-50">
                  <Button
                    buttonVariant="primary"
                    :buttonText="isEdit ? 'Save Changes' : 'Submit'"
                    classes="w-100"
                    type="submit"
                    :isLoading="isLoading"
                  />
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </BasicModal>
    <ConfirmModal
      id="delete-modal"
      :isLoading="isLoading"
      :isSuccess="deleteSuccess"
      successText="Fish Spot Deleted Successfully !"
      :onOkayPress="handleOkayPress"
      :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this fish spot?"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BLink,
  BTooltip,
  BFormInput,
  BFormGroup,
  VBTooltip,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  alphaNum,
  max,
  alphaSpaces,
  image,
  size,
} from "@validations";
import { ref } from "@vue/composition-api";

import DataTable from "../../components/data-table/DataTable.vue";
import SearchHeader from "../../components/SearchHeader.vue";
import Button from "../../components/Button.vue";
import BasicModal from "../../components/BasicModal.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import Input from "../../components/Input.vue";
import Select from "../../components/Select.vue";
import GooglePlacesInput from "../../components/GooglePlacesInput.vue";
import showToast from "../../hooks/useToast";
import { SortOrder } from "../../constants";
import {
  getSpot,
  deleteSpot,
  createSpot,
  updateSpot,
} from "../../api/api.service";
import { useLocalPagination } from "../../hooks/useLocalPagination";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const config = [
  {
    label: "No",
    key: "No",
    tdClass: "table-row",
    thClass: "table-header",
  },
  {
    label: "LOCATION NAME",
    key: "LocationName",
    tdClass: "table-row",
    thClass: "table-header",
  },
  {
    label: "STATE",
    key: "State",
    tdClass: "table-row",
    thClass: "table-header",
    isSortable: true,
  },
  {
    label: "COUNTRY",
    key: "Country",
    tdClass: "table-row",
    thClass: "table-header",
    isSortable: true,
  },
  {
    label: "ACTIONS",
    key: "Actions",
    tdClass: "table-row",
    thClass: "table-header",
  },
];

export default {
  name: "FishingSpots",
  components: {
    DataTable,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    SearchHeader,
    BFormInput,
    Button,
    BFormGroup,
    BasicModal,
    Input,
    GooglePlacesInput,
    Select,
    VBTooltip,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ConfirmModal,
  },

  setup(props, context) {
    const locationName = ref("");
    const location = ref([]);
    const countryName = ref("");
    const stateName = ref("");
    const isEdit = ref(false);
    const locationId = ref(null);
    const isLoading = ref(false);
    const isDeleting = ref(false);
    const deleteSuccess = ref(false);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSortChange,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      request,
    } = useLocalPagination(
      getSpot,
      () => ({
        Direction: SortOrder.DESC,
        Column: "CreatedAt",
      }),
      [],
      "Q",
      "FishSpots",
      "TotalFishSpots"
    );

    function handleLocationSelect(val) {
      locationName.value = val.name;
      let state = val.address_components.find((item) =>
        item.types.includes("administrative_area_level_1")
      );

      let country = val.address_components.find((item) =>
        item.types.includes("country")
      );

      if (state) stateName.value = state.short_name;
      if (country) countryName.value = country.short_name;

      let addressLocation = val?.geometry?.location;
      location.value = [addressLocation.lat(), addressLocation.lng()];
    }

    function handleAddClick() {
      isEdit.value = false;
      location.value = [];
      locationName.value = "";
      stateName.value = "";
      countryName.value = "";
      this.$bvModal.show("modal-1");
    }

    const handleDeleteClick = (id) => {
      locationId.value = id;
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    function deletefishSpot() {
      isDeleting.value = true;
      handleDelete().then((res) => {
        this.$bvModal.hide("modal-1");
        showToast(context.root, {
          Title: "Deleted Successfully",
          Icon: "XCircleIcon",
          Text: res.Message,
          Variant: "danger",
        });
      });
    }

    function handleDelete() {
      return deleteSpot(locationId.value)
        .then((res) => {
          data.value = data.value.filter(
            (item) => item.Id !== locationId.value
          );

          total.value = total.value - 1;

          if (total.value && !Boolean(data.value.length)) {
            page.value = page.value - 1;
          }

          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        })
        .finally(() => {
          isLoading.value = false;
          isDeleting.value = false;
        });
    }

    function handleYesPress(e) {
      isLoading.value = true;

      handleDelete()
        .then(() => {
          deleteSuccess.value = true;
        })
        .catch((err) => {});
    }

    const handleOkayPress = () => {
      deleteSuccess.value = false;
      locationId.value = null;
    };

    const handleEditClick = (data) => {
      isEdit.value = true;
      locationName.value = data.item.Address;
      stateName.value = data.item.State;
      countryName.value = data.item.Country;
      locationId.value = data.item.Id;
      if (data?.Location?.coordinates.length) {
        location = [data.Location.coordinates[0], data.Location.coordinates[1]];
      }
    };

    async function validateForm() {
      let canSubmit = await this.$refs.validationRules.validate();

      if (!canSubmit || isLoading.value || isDeleting.value) {
        return;
      }

      isLoading.value = true;

      let payload = {
        Address: locationName.value,
        State: stateName.value,
        Country: countryName.value,
        Location: location.value,
      };

      let requestFunc = isEdit.value
        ? updateSpot(locationId.value, payload)
        : createSpot(payload);

      requestFunc
        .then((res) => {
          this.$bvModal.hide("modal-1");

          if (isEdit.value) {
            data.value = data.value.map((item) => {
              if (item.Id === locationId.value) {
                return {
                  ...item,
                  ...payload,
                };
              }

              return item;
            });
            showToast(context.root, {
              Title: "Updated Successfully",
              Icon: "CheckCircleIcon",
            });
          } else {
            if (page.value !== 1) {
              page.value = 1;
            } else {
              request();
            }
            showToast(context.root, {
              Title: "Created Successfully",
              Icon: "CheckCircleIcon",
            });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      modalId: "modal-1",
      config,
      locationName,
      countryName,
      stateName,
      isEdit,
      // Validation Data
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      alphaNum,
      max,
      alphaSpaces,
      image,
      size,
      validateForm,
      ////////////
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      isEdit,
      isLoading,
      isDeleting,
      sortOrder,
      sortColumn,
      deleteSuccess,
      handleSortChange,
      handleSearchChange,
      handlePageChange,
      handlePageLimitChange,
      handleAddClick,
      handleDeleteClick,
      handleOkayPress,
      handleYesPress,
      handleEditClick,
      handleLocationSelect,
      deletefishSpot,
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.delete-icon {
  color: red;
}

.detail-icon {
  color: #F78F21;
}
::v-deep .table-row {
  font-weight: bold;
}
</style>
