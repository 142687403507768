import { ref, watch, onMounted } from '@vue/composition-api'
import { useStore } from '../store'
import config from '../config'

export const usePaginatedRequest = (
    requestFn,
    getParams = () => ({}),
    deps = [],
    searchKey = 'Q'
) => {
    const store = useStore()
    const page = ref(1)
    const pageLimit = ref(config.LIMIT)
    const searchText = ref('')
    const sortOrder = ref(null)
    const sortColumn = ref(null)
    let requestAbortController = null

    watch(
        [searchText, page, pageLimit, sortOrder, sortColumn, ...deps],
        () => {
            request()
        },
        { deep: true }
    )

    onMounted(() => {
        request()
    })

    function request() {
        if (requestAbortController && requestAbortController.abort) {
            requestAbortController.abort()
        }
        requestAbortController = new AbortController()
        store.dispatch(requestFn, {
            page: page.value,
            limit: pageLimit.value,
            ...(searchText.value && { [searchKey]: searchText.value }),
            ...(Object.keys(getParams()).length && getParams()),
            ...(sortOrder.value &&
                sortColumn.value && {
                    // column: sortColumn.value,
                    // direction: sortOrder.value,
                }),
            signal: requestAbortController.signal,
        })
    }

    const handleSearchChange = (val) => {
        searchText.value = val
        page.value = 1
    }

    const handlePageClick = (pageNo) => {
        page.value = pageNo
    }

    const handlePageLimit = (noOfRecords) => {
        page.value = 1
        pageLimit.value = noOfRecords
    }

    const handleSortChange = (column, order) => {
        page.value = 1
        if (sortOrder.value === order && sortColumn.value === column) {
            sortOrder.value = null
            sortColumn.value = null
            return
        }
        sortOrder.value = order
        sortColumn.value = column
    }

    return {
        page,
        pageLimit,
        searchText,
        sortColumn,
        sortOrder,
        request,
        handleSearchChange,
        handlePageClick,
        handlePageLimit,
        handleSortChange,
    }
}
