<template>
	<div :class="width">
		<b-card no-body class="w-100 card-box">
			<div>
				<!-- <div class="p-1">
					<template v-if="hasCustomButtons">
						<slot name="customGroupButtons"></slot>
					</template>
					<div v-else>
						<GroupedButton
							size="md"
							:buttonConfig="buttonConfig"
							groupContainerClasses="stats-group-btn"
						/>
					</div>
				</div> -->

				<b-card-body>
					<div
						class="
							d-flex
							justify-content-between
							align-items-center
						"
					>
						<div class="truncate">
							<div
								v-if="fetching"
								class="
									w-100
									h-100
									d-flex
									justify-content-center
									align-items-center
								"
							>
								<b-spinner variant="primary" class="spinner" />
							</div>
							<div>
							<b-avatar :class="[iconColor]" size="45">
							<template v-if="iconName">
								<feather-icon size="21" :icon="iconName" />
							</template>
							<template v-else>
								<slot name="customIcon"></slot>
							</template>
						</b-avatar>
											<span
								class="sub-text-color statisticsTitle"
								:class="statisticTitleClasses"
								>{{ statisticTitle }}</span
							>
							</div>
							<h2
								v-if="!fetching"
								class="
									mb-25
									font-weight-bolder
									text-secondary
									statistics
								"
								:class="statisticsClasses"
							>
								{{ statistic }}
							</h2>
						</div>
						
					</div>
				</b-card-body>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BAvatar, BSpinner } from 'bootstrap-vue'
import GroupedButton from './GroupedButton.vue'
export default {
	name: 'StatisticsWidget',
	components: {
		BCard,
		BCardBody,
		BAvatar,
		BSpinner,
		GroupedButton,
	},
	props: {
		iconName: {
			type: String,
		},
		iconColor: String,
		statistic: {
			type: [Number, String],
			required: true,
		},
		statisticsClasses: String,
		statisticTitleClasses: String,
		statisticTitle: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '',
		},
		iconColor: {
			type: String,
			default: 'primary',
		},
		width: {
			type: String,
			default: '',
		},
		data: {
			type: Object,
		},
		onClick: Function,
		fetching: false,
		hasCustomButtons: false,
	},
	methods: {
		handleBtnClick(filter, id) {
			this.$props.onClick({ FilterType: filter })

			this.buttonConfig = this.buttonConfig.map((item) => {
				return {
					...item,
					activeClass: item.id === id ? true : false,
				}
			})
		},
	},
	data() {
		return {
			buttonConfig: [
				{
					id: 1,
					btnText: 'Today',
					trigger: () => this.handleBtnClick(1, 1),
					activeClass: true,
				},
				{
					id: 2,
					btnText: 'Weekly',
					trigger: () => this.handleBtnClick(2, 2),
					activeClass: false,
				},
				{
					id: 3,
					btnText: 'Monthly',
					trigger: () => this.handleBtnClick(4, 3),
					activeClass: false,
				},
			],
		}
	},
}
</script>

<style scoped>
.statistics {
	font-size: 20px;
	color: #F78F21 !important;
	margin-top:5px;
}
.statisticsTitle {
	font-size: 15px;
	margin-left:20px;
	font-weight:bold;
	margin-top:5px;
}
.sub-text-color {
}
.background-color {
	background-color: #67f0821f;
}
/* Icon Colors, need to pass these class in iconColor props */
.revenue-icon {
	color: #338746;
	background-color: #67f0821f;
}
.users-icon {
	color: #000000;
	background-color: #7367f01f;
}
.subscribed-users-icon {
	color: #d8ac35;
	background-color: #fbf6e2;
}
.total-guides-icon {
	color: #226694;
	background-color: #3790d51f;
}
.spinner {
	width: 2rem;
	height: 2rem;
}
.card-box {
	height: 100px;
}
.truncate{
	display: flex;
	flex-direction :row;
	justify-content: space-between;
	width:100%;

}
</style>