<template>
	<b-card class="body-container">
		<div class="loader-container" v-if="isLoading">
			<b-spinner class="loader" variant="primary"></b-spinner>
		</div>
		<div class="loader-container" v-if="!isLoading && !groupPostData">
			<p class="msg">Group post details not found !</p>
		</div>
		<b-container v-if="groupPostData && !isLoading">
			<div>
				<b-row>
					<b-col cols="12" md="6" lg="4" sm="12">
						<LabelText
							label="Posted By"
							:text="
								(groupPostData &&
									groupPostData.User.FullName) ||
								'-'
							"
							showRightSection
							textClasses="font-weight-bolder mt-0"
						>
							<b-avatar
								size="md"
								:src="getMediaPath(groupPostData.User)"
								:text="
									groupPostData.User &&
									groupPostData.User.FullName[0]
								"
								class="avatar"
							/>
						</LabelText>

						<div class="neg-margin mt-3">
							<LabelText
								label="Date"
								:text="
									convertDateTime({
										date: groupPostData.CreatedAt,
										customFormat:
											dateTimeFormat.appDateFormat,
										dateOnly: true,
									})
								"
								showRightSection
								textClasses="font-weight-bolder"
							/>
						</div>
						<div class="neg-margin"></div>
					</b-col>
					<b-col cols="12" md="6" lg="4" sm="12">
						<LabelText
							label="Group Name"
							:text="groupPostData.Group.Name || '-'"
							textClasses="font-weight-bolder"
						/>
						<!-- <LabelText
							label="Location"
							:text="groupPostData.User.CountryCodeAlpha || '-'"
							textClasses="font-weight-bolder"
						/> -->
						<!-- <LabelText
							label="Report Type"
							:text="
								ReportType.getDisplayTextKey(
									groupPostData.ReportType
								) || '-'
							"
							textClasses="font-weight-bolder"
						/> -->
					</b-col>
					<b-col cols="12" md="12" lg="4" sm="12">
						<Button
							buttonText="Delete Post"
							buttonVariant="danger"
							classes="mb-1 w-50"
							:clickHandler="handleDeletePress"
						/>
					</b-col>
				</b-row>
				<hr />

				<b-row>
					<b-col cols="12" md="6" lg="6" sm="12">
						<h6 class="text-secondary mb-3 font-weight-bolder">
							Post Text
						</h6>
						<p style="color: black">
							{{
								groupPostData.Description ||
								'No content to show...'
							}}
						</p>
					</b-col>

					<b-col cols="12" md="6" lg="6" sm="12">
						<h6 class="text-secondary mb-3 font-weight-bolder">
							Post Images
						</h6>
						<div v-if="Boolean(groupPostData.Medias.length)">
							<ImageGallery
								:galleryConfig="groupPostData.Medias"
							/>
						</div>
						<div v-else style="color: black">No Images</div>
					</b-col>
				</b-row>
			</div>
		</b-container>
		<ConfirmModal
			id="post-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Post Deleted Successfully !"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this post?"
		/>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
	BSpinner,
	VBModal,
	BContainer,
} from 'bootstrap-vue'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import Button from '../../components/Button.vue'
import ImageGallery from '../../components/ImageGallery.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { APP_ROUTES } from '../../helpers/routeHelpers'
import { groupPostDetails, deleteGroupPostDetails } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { ReportType } from '../../constants'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'

export default {
	name: 'GroupPostDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BSpinner,
		BContainer,
		BAvatar,
		Ratings,
		Button,
		ImageGallery,
		LabelText,
		ConfirmModal,
		VBModal,
	},
	created() {
		this.isLoading = true
		groupPostDetails(this.$route.params.postId)
			.then((res) => {
				this.groupPostData = res
			})
			.catch((err) => {})
			.finally(() => {
				this.isLoading = false
			})
	},
	methods: {
		handleYesPress() {
			this.isProcessing = true

			deleteGroupPostDetails(Number(this.$route.params.postId))
				.then((res) => {
					this.deleteSuccess = true
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		handleOkayPress() {
			this.deleteSuccess = false
			this.$router.replace(APP_ROUTES.MODERATION_GROUP_POSTS)
		},
		handleDeletePress() {
			this.$bvModal.show('post-modal')
		},
	},
	data() {
		return {
			groupPostData: null,
			isLoading: false,
			isLoading: false,
			isProcessing: false,
			deleteSuccess: false,
			ReportType,
			dateTimeFormat,
			convertDateTime,
			getMediaPath,
		}
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.review-height {
	line-height: 25px;
}
.add-notes-title {
	color: #888888;
}
.top-add-notes {
	margin-top: 5px;
}
.add-notes-title + p {
	color: black;
}
.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 60vh;
}
.loader {
	margin-bottom: 8px;
	width: 4rem;
	height: 4rem;
}

.msg {
	font-size: 20px;
	font-weight: 500;
}

.avatar {
	border: 1px solid white;
}
.body-container {
	margin-top: -10px;
}
.neg-margin {
	margin-top: -12px;
}
</style>
