<template>
	<div id="app" class="h-100" :class="[skinClasses]">
		<component :is="layout" v-if="!isLoading && !isServerError">
			<router-view />
		</component>

		<under-maintenance
			v-if="isAuthenticated && isServerError && !isLoading"
		></under-maintenance>

		<div class="loader-container" v-if="isLoading">
			<Loader classes="loader" variant="primary" />
			<p>Verifying...</p>
		</div>
	</div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, ref, onMounted, computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { APP_ROUTES } from './helpers/routeHelpers'
import { mapActions } from 'vuex'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { useStore } from '@/store'
import { GET_ME } from './store/auth'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () =>
	import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const UnderMaintenance = () => import('@/views/auth/UnderMaintenance.vue')

import Loader from './components/Loader.vue'
import { UserRoles } from './constants'

export default {
	components: {
		// Layouts
		LayoutHorizontal,
		LayoutVertical,
		LayoutFull,
		UnderMaintenance,
		Loader,
	},
	// ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
	// Currently, router.currentRoute is not reactive and doesn't trigger any change
	computed: {
		layout() {
			if (this.$route.meta.layout === 'full') return 'layout-full'
			return `layout-${this.contentLayoutType}`
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type
		},
	},
	methods: {
		...mapActions([GET_ME]),
	},

	beforeCreate() {
		// Set colors in theme

		const colors = [
			'primary',
			'secondary',
			'success',
			'info',
			'warning',
			'danger',
			'light',
			'dark',
		]

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(
				`--${colors[i]}`,
				document.documentElement
			).value.trim()
		}

		// Set Theme Breakpoints
		const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(
				useCssVar(
					`--breakpoint-${breakpoints[i]}`,
					document.documentElement
				).value.slice(0, -2)
			)
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout
		document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
	},
	setup(props, { root }) {
		const { skin, skinClasses } = useAppConfig()
		const store = useStore()
		const isLoading = ref(false)
		const isServerError = ref(false)
		const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
		const currentUserType = computed(() => store?.state?.auth?.user?.Type)

		watch(
			() => isAuthenticated.value,
			(newVal, oldVal) => {
				if (newVal && !root.$route.meta.requiresAuth) {
					// if (currentUserType.value == UserRoles.Moderator) {
					// 	root.$router.push(APP_ROUTES.COMMUNITY_POSTS)
					// } else {
						root.$router.push(APP_ROUTES.DASHBOARD)
				//	}
				} else if (!newVal && root.$route.meta.requiresAuth) {
					root.$router.push(APP_ROUTES.LOGIN)
				}
			}
		)

		onMounted(() => {
			if (isAuthenticated.value) {
				isLoading.value = true
				store
					.dispatch(GET_ME)
					.then((res) => {})
					.catch(() => {
						isServerError.value = true
					})
					.finally(() => {
						isLoading.value = false
					})
			}
		})

		// If skin is dark when initialized => Add class to body
		if (skin.value === 'dark') document.body.classList.add('dark-layout')

		// Provide toast for Composition API usage
		// This for those apps/components which uses composition API
		// Demos will still use Options API for ease
		provideToast({
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: false,
			timeout: 3000,
			transition: 'Vue-Toastification__fade',
		})

		// Set Window Width in store
		store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
		const { width: windowWidth } = useWindowSize()
		watch(windowWidth, (val) => {
			store.commit('app/UPDATE_WINDOW_WIDTH', val)
		})

		return {
			isServerError,
			isLoading,
			skinClasses,
			isAuthenticated,
		}
	},
}
</script>

<style scoped>
.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.loader {
	margin-bottom: 8px;
	width: 4rem;
	height: 4rem;
}
</style>
