<template>
	<div>
		<b-table
			:items="data"
			responsive
			:fields="fields"
			primary-key="id"
			show-empty
			:busy="isLoading"
			:empty-text="message"
			class="position-relative min-height-55vh"
			:class="[tableClasses]"
			:tbody-tr-class="rowClass"
		>
			<template
				v-for="(item, index) in config"
				v-slot:[`head(${item.key})`]="data"
			>
				<div
					class="d-flex flex-row"
					v-bind:key="index"
					v-if="item.isSortable"
				>
					<span>{{ data.label }}</span>
					<div class="d-flex flex-column ml-1">
						<img
							:src="
								currentSortOrder === SortOrder.DESC &&
								currentSortColumn === item.key
									? require('@/assets/images/icons/sorting-up-dark.svg')
									: require('@/assets/images/icons/sorting_up.svg')
							"
							class="sorting"
							@click="onSortClick(item.key, SortOrder.DESC)"
						/>
						<img
							:src="
								currentSortOrder === SortOrder.ASC &&
								currentSortColumn === item.key
									? require('@/assets/images/icons/sorting-down-dark.svg')
									: require('@/assets/images/icons/sorting_down.svg')
							"
							class="sorting"
							@click="onSortClick(item.key, SortOrder.ASC)"
						/>
					</div>
				</div>
			</template>

			<template #table-busy>
				<div class="text-center text-primary my-2">
					<b-spinner class="align-middle"></b-spinner>
				</div>
			</template>
			<template #empty>
				<div class="d-flex justify-content-center">
					<img :src="emptyImage" class="w-25 h-25 text-center" />
				</div>
			</template>
			<template
				v-for="(_, slotName) of $scopedSlots"
				v-slot:[slotName]="scope"
			>
				<slot :name="slotName" v-bind="scope" />
			</template>
		</b-table>
		<pagination
			:noOfItems="data.length"
			:perPage="perPageLimit"
			:entriesName="entriesName"
			:value="selectedPageValue"
			:onChange="onHandlePageChange"
			v-if="showPagination && data.length !== 0"
			:totalRecords="totalRecords"
		/>
	</div>
</template>

<script>
import { BTable, BSpinner } from 'bootstrap-vue'

import Pagination from './Pagination.vue'
import { SortOrder } from '@/constants'

export default {
	name: 'DataTable',
	components: {
		BTable,
		Pagination,
		BSpinner,
	},
	props: {
		config: {
			type: Array,
			required: true,
		},
		tableClasses: String,
		data: {
			type: Array,
			required: true,
		},

		perPage: {
			type: Number,
			default: 10,
		},
		totalRecords: {
			type: Number,
		},
		showPagination: {
			type: Boolean,
			default: true,
		},
		isLoading: {
			default: false,
			type: Boolean,
		},
		onHandlePageChange: Function,
		selectedPageValue: {
			type: String,
			default: 1,
		},
		entriesName: String,
		onSortClick: Function,
		currentSortOrder: String,
		currentSortColumn: String,
		rowClass: String,
		currentPage: {
			type: Number,
			default: 1,
		},
		perPageLimit: {
			type: String,
			default: 10,
		},
	},
	data() {
		return {
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			fields: [],
			defaultFields: {
				thClass: 'table-header',
				tdClass: 'table-row',
			},
			SortOrder,
		}
	},
	created() {
		this.fields = this.config.map((item) => {
			return { ...this.defaultFields, ...item }
		})
	},
}
</script>

<style scoped>
::v-deep .table-header {
	color: #F78F21 !important;
	font-weight: bolder !important;
	font-size: 0.9rem !important;
	overflow: hidden !important;
	white-space: nowrap !important;
}
::v-deep .table-row {
	color: black;
	font-weight: normal;
	overflow: hidden !important;
	white-space: nowrap !important;
}
.min-height-55vh {
	min-height: 55vh !important;
}
.sorting {
	width: 5px;
	height: 9px;
	cursor: pointer;
}
</style>
